import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { NormalizedEntities } from '../../library/App';
import { RootState } from '../../rootReducer';
import { normalizeEntities } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';

const create = actionCreatorFactory(config.CONTACT_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadPpoContactInfo = createAsync<void, NormalizedEntities>(
  'GET_PPO_CONTACT_INFO',
  async (parameters, dispatch, getState, { SportnetApi }) => {
    const appSpace = activeAppspaceSelector(getState());
    const contactInfo = await SportnetApi.organizationPPOContactInfo(appSpace);
    return normalizeEntities('PPO', [{ contactInfo, _id: appSpace }]);
  },
);
