import { commit, CommitError, getListParameters } from 'redux-list';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import CompetitionsApi from '../../CompetitionsApi';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import { ISportSectorSetting, NormalizedEntities } from '../../library/App';
import { RootState } from '../../rootReducer';
import { normalizeEntities } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';

export const TEAM_COMPETITIONS_LIST_NAME = 'TEAM_COMPETITIONS_LIST';

const create = actionCreatorFactory(config.TEAMS_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadTeamById = createAsync<{ teamId: string }, NormalizedEntities>(
  'GET_TEAM_BY_ID',
  async (parameters, dispatch, getState) => {
    const appSpace = activeAppspaceSelector(getState());
    const response = await CompetitionsApi.getTeam(appSpace, parameters.teamId);
    return normalizeEntities('TEAMS', [response]);
  },
);

export const loadTeamCompetitions = createAsync<
  { teamId: string },
  NormalizedEntities
>(
  'GET_TEAM_COMPETITIONS',
  async (parameters, dispatch, getState, { SportnetApi }) => {
    const appSpace = activeAppspaceSelector(getState());
    const response = await CompetitionsApi.getTeamCompetitions(
      appSpace,
      parameters.teamId,
    );
    return normalizeEntities('COMPETITIONS', response.competitions);
  },
);

export const loadTeamCompetitionsList = (): CustomThunkAction<Promise<
  void
>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(TEAM_COMPETITIONS_LIST_NAME)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: TEAM_COMPETITIONS_LIST_NAME,
        load: async () => {
          try {
            const result = await dispatch(
              loadTeamCompetitions.action({
                teamId: parameters.teamId,
              }),
            );
            return {
              total: result.results.length,
              results: result.results,
            };
          } catch (e) {
            throw new CommitError();
          }
        },
      }),
    );
  };
};

export const getTeamCompetitionDetail = createAsync<
  { teamId: string; competitionId: string },
  NormalizedEntities
>('GET_TEAM_COMPETITION_DETAIL', async (parameters, dispatch, getState) => {
  const appSpace = activeAppspaceSelector(getState());
  const response = await CompetitionsApi.getTeamCompetition(
    appSpace,
    parameters.teamId,
    parameters.competitionId,
  );
  return normalizeEntities('COMPETITIONS', [response]);
});

export const getSportSectorsPhases = createAsync<void, NormalizedEntities>(
  'GET_SPORT_SECTORS_PHASES',
  async (parameters, dispatch, getState) => {
    const response = (await CompetitionsApi.getSettingById(
      'sport_sector_phases',
    )) as any;
    return {
      entities: {
        SPORT_SECTOR_PHASES: response.sport_sectors.reduce(
          (acc: { [key: string]: any }, item: any) => {
            acc[item._id] = item;
            return acc;
          },
          {},
        ),
      },
      results: response.sport_sectors.map((item: any) => item._id),
    };
  },
);

export const getSportSectorsStats = createAsync<void, NormalizedEntities>(
  'GET_SPORT_SECTORS_STATS',
  async (parameters, dispatch, getState) => {
    const response = (await CompetitionsApi.getSettingById(
      'sport_sector_stats',
    )) as any;
    return {
      entities: {
        SPORT_SECTOR_STATS: response.sport_sectors.reduce(
          (acc: { [key: string]: any }, item: any) => {
            acc[item._id] = item;
            return acc;
          },
          {},
        ),
      },
      results: response.sport_sectors.map((item: any) => item._id),
    };
  },
);

export const getSportSectorsSettings = createAsync<
  { settingSubId: string },
  NormalizedEntities
>('GET_SPORT_SECTOR_SETTINGS', async (parameters, dispatch, getState) => {
  const response = (await CompetitionsApi.getSettingById(
    'sport_sector_settings',
  )) as any;
  return {
    entities: {
      SPORT_SECTOR_SETTINGS: response.sport_sectors.reduce(
        (
          acc: { [key: string]: ISportSectorSetting },
          s: {
            _id: string;
            items: Array<{ _id: string; value: ISportSectorSetting }>;
          },
        ) => {
          const setting = s.items.find(
            (i: { _id: string }) => i._id === parameters.settingSubId,
          );
          return { ...acc, [s._id]: !!setting && setting.value };
        },
        {},
      ),
    },
    results: response.sport_sectors.map((item: any) => item._id),
  };
});
