import FormField from '@sportnet/ui/lib/FormField';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { ReduxConnectProps } from '../../configureStore';
import { CodelistItem } from '../../library/App';
import { codelistItemsSelector } from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities';

const mapStateToProps = (state: RootState) => ({
  sportOrgCompetenceTypeCodelist: codelistItemsSelector(
    'sport-org-competence-type',
  )(state),
  sportExpertCompetenceTypeCodelist: codelistItemsSelector(
    'sport-expert-competence-type',
  )(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
  setParameter: (parameters: { [key: string]: any }) => void;
  affiliationCategory: string;
  competenceType: string;
  affiliationDateFrom: string;
  affiliationDateTo: string;
}

type Props = OwnProps & IMapStateToProps & ReduxConnectProps;

class MembersFilter extends React.PureComponent<Props> {
  handleChangeParameter = (name: string) => (value: string | number) => {
    this.props.setParameter({ [name]: value });
  };

  handleEventChangeParameter = (name: string) => (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    this.handleChangeParameter(name)((e.target as HTMLInputElement).value);
  };

  handleChangeAffiliationCategoryParameter = (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const update: {
      affiliationCategory: string;
      competenceType?: string;
    } = {
      affiliationCategory: (e.target as HTMLInputElement).value,
    };
    if (this.props.affiliationCategory !== update.affiliationCategory) {
      update.competenceType = '';
    }
    this.props.setParameter(update);
  };

  handleChangeDateParameter = (name: string) => (date: Moment) => {
    const update = {
      [name]: '',
    };
    if (date) {
      update[name] = date.toJSON();
    }
    this.props.setParameter(update);
  };

  renderCodelistOption = (item: CodelistItem) => (
    <option value={item.value} key={item.value}>
      {item.label || item.value}
    </option>
  );

  getMomentInstance = (date: string) => (date ? moment(date) : null);

  render() {
    const {
      affiliationCategory,
      competenceType,
      affiliationDateFrom,
      affiliationDateTo,
      sportOrgCompetenceTypeCodelist,
      sportExpertCompetenceTypeCodelist,
    } = this.props;
    return (
      <>
        <FormField
          label={__('Príslušnosť')}
          name="affiliationCategory"
          type="select"
          value={affiliationCategory}
          onChange={this.handleChangeAffiliationCategoryParameter}
        >
          <option value="" key="all">
            {__('Všetky')}
          </option>
          <option value="sport_orgs" key="sport_orgs">
            {__('Športovec')}
          </option>
          <option value="sport_expert_orgs" key="sport_expert_orgs">
            {__('Športový odborník')}
          </option>
        </FormField>
        <FormField
          label={__('Typ')}
          name="competenceType"
          type="select"
          value={competenceType}
          onChange={this.handleEventChangeParameter('competenceType')}
          title={
            affiliationCategory
              ? ''
              : __('Filter bude dostupný po výbere príslušnosti')
          }
          disabled={!affiliationCategory}
        >
          <option value="" key="all">
            {__('Všetky')}
          </option>
          {affiliationCategory === 'sport_orgs'
            ? sportOrgCompetenceTypeCodelist.map(this.renderCodelistOption)
            : sportExpertCompetenceTypeCodelist.map(this.renderCodelistOption)}
        </FormField>
        <FormField
          label={__('Aktívny od')}
          name="affiliationDateFrom"
          type="date"
          value={this.getMomentInstance(affiliationDateFrom)}
          onChange={this.handleChangeDateParameter('affiliationDateFrom')}
        />
        <FormField
          label={__('Aktívny do')}
          name="affiliationDateTo"
          type="date"
          value={this.getMomentInstance(affiliationDateTo)}
          onChange={this.handleChangeDateParameter('affiliationDateTo')}
        />
      </>
    );
  }
}

export default connect<IMapStateToProps>(mapStateToProps)(MembersFilter);
