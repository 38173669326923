import { commit, CommitError, getListParameters } from 'redux-list';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import { NormalizedEntities } from '../../library/App';
import { RootState } from '../../rootReducer';
import { normalizeEntities } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';
import { LIST_NAME } from './';

const create = actionCreatorFactory(config.ORGANIZATIONS_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

interface Parameters {
  q?: string;
  ppoType?: string;
}

const loadPPOs = createAsync<
  Parameters & { appSpace: string },
  NormalizedEntities
>('GET_PPOS', async (parameters, dispatch, getState, { SportnetApi }) => {
  const params: { ppoType?: string[] } = {};
  if (parameters.ppoType) {
    params.ppoType = [parameters.ppoType];
  }
  const response = await SportnetApi.organizationPPOListRelatedPPOs(
    parameters.appSpace,
    params,
  );
  return normalizeEntities('PPO', response.items!);
});

export const loadPPOList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const appSpace = activeAppspaceSelector(getState());
    const parameters = getListParameters(LIST_NAME)(getState());
    return dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          try {
            const { results } = await dispatch(
              loadPPOs.action({ ...parameters, appSpace }),
            );
            return {
              total: results.length,
              results,
            };
          } catch (e) {
            throw new CommitError();
          }
        },
      }),
    );
  };
};
