import FormGroup from '@sportnet/ui/lib/FormGroup';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import Label from '@sportnet/ui/lib/Label/Label';
import { darken, rem } from 'polished';
import * as React from 'react';
import styled from '../../theme/styled-components';

const Value = styled('div')`
  width: 100%;
  flex: 1 0 0;
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.fontFamily};
  padding: ${rem(10)} 0;
  word-break: break-word;
  & > a {
    width: 100%;
    flex: 1 0 0;
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.color.primary};
    font-family: ${({ theme }) => theme.fontFamily};
    padding: ${rem(10)} 0;
    word-break: break-word;
    text-decoration: none;
    :hover {
      color: ${({ theme }) => darken(0.15, theme.color.primary)};
    }
  }
`;

export interface Field {
  label: string;
  value: React.ReactNode;
  sizes?: {
    xs?: number;
    s?: number;
    m?: number;
    l?: number;
    xl?: number;
  };
}

type Props = {
  fields: ReadonlyArray<Field>;
};

class ReadOnlyForm extends React.PureComponent<Props> {
  detaults = {
    sizes: {
      m: 6,
    },
  };

  renderField = (field: Field, idx: number) => {
    const sizes = field.sizes || this.detaults.sizes;
    return (
      <Col {...sizes} key={idx}>
        <FormGroup>
          <Label>{field.label}</Label>
          <Value>{field.value}</Value>
        </FormGroup>
      </Col>
    );
  };

  render() {
    const { fields } = this.props;
    return <Row>{fields.map(this.renderField)}</Row>;
  }
}

export default ReadOnlyForm;
