// App related config
export default {
  APP_ID: 'crm.public',
  DEFAULT_LIST_LIMIT: 20,
  APP_NS: 'APP',
  THEMER_NS: 'THEMER',
  MEMBERS_NS: 'MEMBERS',
  TEAMS_NS: 'TEAMS',
  MEMBER_NS: 'MEMBER',
  ORGANIZATIONS_NS: 'ORGANIZATIONS',
  CONTACT_NS: 'CONTACT',
};
