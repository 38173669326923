import format from 'date-fns/format';
import * as sk from 'date-fns/locale/sk';
import { NormalizedEntities } from './library/App';

export const formatDate = (
  date: string | number | Date,
  formatStr?: string,
) => {
  return format(date, formatStr, {
    locale: sk,
  });
};

export const __ = (text: string) => text;

export const getOrgProfileLogoSrc = (appSpace: string): string => {
  return `${process.env.REACT_APP_SPORTNET_API_BASE_URL}/ppo/${appSpace}/logo`;
};

export const getUserPhotoSrc = (userId: string, token: string) => {
  return `${process.env.REACT_APP_SPORTNET_API_BASE_URL}/users/${userId}/photo/${token}`;
};

export const getSportCodelistKey = (sport?: string, sector?: string) => {
  let key = 'sport';
  if (sport) {
    key += `/${sport}/sector`;
  }
  if (sector) {
    key += `/${sector}/discipline`;
  }
  return key;
};

export const normalizeEntities = <T>(
  type: string,
  arrayOfEntities: T[],
  id: string = '_id',
): NormalizedEntities => {
  return {
    entities: {
      [type]: arrayOfEntities.reduce((acc: { [key: string]: T }, item: any) => {
        acc[item[id]] = item;
        return acc;
      }, {}),
    },
    results: arrayOfEntities.map((item: any) => item[id]),
  };
};

export const at = <T>(object: { [key: string]: T }, keys: string[]) => {
  return keys.reduce((acc: T[], key) => {
    const item = object[key];
    if (typeof item !== 'undefined' && item !== null) {
      acc.push(item);
    }
    return acc;
  }, []);
};

export const getAppSpace = (props: { hostname?: string }) => {
  const splitter = process.env.REACT_APP_FIXED_DOMAIN_NAME as string;
  if (!splitter) {
    throw new Error('REACT_APP_FIXED_DOMAIN_NAME_NOT_DEFINED');
  }
  let hostname = '';
  if (props && props.hostname) {
    hostname = props.hostname;
  }
  if (typeof window.location !== 'undefined') {
    hostname = window.location.hostname;
  }
  const [appSpace] = hostname.split(`.${splitter}`);
  return appSpace;
};

export const getPPOUrl = (PPO: any) => {
  const port = process.env.REACT_APP_PORT;
  const protocol = process.env.REACT_APP_PROTOCOL;
  return `${protocol}://${PPO._id}.${process.env.REACT_APP_FIXED_DOMAIN_NAME}${
    port ? `:${port}` : ''
  }`;
};

export const idealTextColor = (bgColor: string) => {
  const nThreshold = 105;
  const components = getRGBComponents(bgColor);
  const bgDelta =
    components.R * 0.299 + components.G * 0.587 + components.B * 0.114;

  return 255 - bgDelta < nThreshold ? '#000000' : '#ffffff';
};

const getRGBComponents = (color: string) => {
  const r = color.substring(1, 3);
  const g = color.substring(3, 5);
  const b = color.substring(5, 7);

  return {
    R: parseInt(r, 16),
    G: parseInt(g, 16),
    B: parseInt(b, 16),
  };
};
