import { getListResults } from 'redux-list';
import { createSelector } from 'reselect';
import { at } from '../../utilities';
import { entitiesSelector } from '../App/selectors';

export const listTeamsSelector = (listName: string) =>
  createSelector(entitiesSelector, getListResults(listName), (entities, ids) =>
    at(entities.TEAMS, ids || []),
  );

export const suitableSeasonsSelector = createSelector(
  entitiesSelector,
  entities =>
    Object.keys(entities.SEASONS || {}).map(k => (entities.SEASONS || {})[k]),
);
