import Segment from '@sportnet/ui/lib/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReadOnlyForm from '../../components/ReadOnlyForm';
import SegmentHeader from '../../components/SegmentHeader';
import { ReduxConnectProps } from '../../configureStore';
import { CodelistLabelByValue, RouteProps } from '../../library/App';
import { PO } from '../../library/PO';
import { PPO } from '../../library/PPO';
import { codelistLabelByValueSelector } from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';
import { Address } from '../../SportnetApi';
import { __ } from '../../utilities';

const mapStateToProps = (
  state: RootState,
  props: RouteProps<{ userId: string }>,
) => {
  return {
    legalFormCodelist: codelistLabelByValueSelector('legal-form')(state),
    countryCodelist: codelistLabelByValueSelector('country')(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  PPO: PPO;
  PO: PO;
};

type Props = OwnProps & ReduxConnectProps & IMapStateToProps;

class InvoiceInfo extends React.PureComponent<Props> {
  formatAddress = (address?: Address): string => {
    if (!address) {
      return '';
    }
    const country = this.props.countryCodelist[address.country || ''] || '';
    return [
      `${address.street || ''} ${address.number || ''}`,
      `${address.zip || ''} ${address.city || ''}`,
      `${country}`,
    ].join(', ');
  };

  getLabelFromCodelist(codelist: CodelistLabelByValue, value: string) {
    if (value && Object.keys(codelist).length > 0) {
      return codelist[value] || value;
    }
    return '';
  }

  render() {
    const { PPO, PO, legalFormCodelist } = this.props;
    return (
      <Segment raised>
        <Segment noBottomGutter>
          <SegmentHeader>{__('Fakturačné údaje')}</SegmentHeader>
          <ReadOnlyForm
            fields={[
              {
                label: __('Názov spoločnosti'),
                value: (PPO as any).name || '',
                sizes: {
                  m: 3,
                  s: 6,
                  xs: 12,
                },
              },
              {
                label: __('Právna forma'),
                value: this.getLabelFromCodelist(
                  legalFormCodelist,
                  PO.legal_form || '',
                ),
                sizes: {
                  m: 5,
                  s: 6,
                  xs: 12,
                },
              },
              {
                label: __('IČO'),
                value: PO.business_id,
                sizes: {
                  m: 2,
                  s: 6,
                  xs: 12,
                },
              },
              {
                label: __('DIČ/IČDPH'),
                value: PO.vat_nr,
                sizes: {
                  m: 2,
                  s: 6,
                  xs: 12,
                },
              },
              {
                label: __('Fakturačná adresa'),
                value: this.formatAddress(PO.invoice_address),
                sizes: {
                  xs: 12,
                },
              },
            ]}
          />
        </Segment>
      </Segment>
    );
  }
}

export default compose(connect(mapStateToProps))(InvoiceInfo as any) as any;
