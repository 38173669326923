import Segment from '@sportnet/ui/lib/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/lib/Table';
import * as React from 'react';
import { connect } from 'react-redux';
import { getProp } from 'sportnet-utilities';
import ScrollWrapper from '../../components/ScrollWrapper';
import SegmentHeader from '../../components/SegmentHeader';
import { ReduxConnectProps } from '../../configureStore';
import { CodelistItem, CodelistItems, RouteProps } from '../../library/App';
import { Member, SportExpertOrg, SportOrg } from '../../library/Member';
import {
  codelistLabelByValueSelector,
  entitiesCodelistsSelector,
} from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';
import { formatDate, getSportCodelistKey, __ } from '../../utilities';

const mapStateToProps = (
  state: RootState,
  props: RouteProps<{ userId: string }>,
) => {
  return {
    codelistsById: entitiesCodelistsSelector(state),
    sportOrgCompetenceTypeCodelist: codelistLabelByValueSelector(
      'sport-org-competence-type',
    )(state),
    sportExpertCompetenceTypeCodelist: codelistLabelByValueSelector(
      'sport-expert-competence-type',
    )(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  user: Member;
};

type Props = OwnProps & ReduxConnectProps & IMapStateToProps;

class UserAffilations extends React.PureComponent<Props> {
  getSportSectorLabel = (sport: string, sector: string): string => {
    const { codelistsById } = this.props;
    const codelistItems = getProp(
      codelistsById,
      [getSportCodelistKey(sport), 'items'],
      [],
    ) as CodelistItems;
    const codelistItem =
      codelistItems.find(item => item.value === sector) || ({} as CodelistItem);
    return codelistItem.label || codelistItem.value || '';
  };

  renderSportOrgRow = (item: SportOrg) => {
    const { sportOrgCompetenceTypeCodelist } = this.props;
    return (
      <Tr key={item._id}>
        <Td>{item.org_profile_name}</Td>
        <Td>
          {item.sport &&
            item.sport_sector &&
            this.getSportSectorLabel(item.sport, item.sport_sector)}
        </Td>
        <Td>{__('Športovec')}</Td>
        <Td>
          {item.competence_type &&
            sportOrgCompetenceTypeCodelist[item.competence_type]}
        </Td>
        <Td>{item.date_from && formatDate(item.date_from, 'DD.MM.YYYY')}</Td>
        <Td>{item.date_to && formatDate(item.date_to, 'DD.MM.YYYY')}</Td>
        <Td>{}</Td>
      </Tr>
    );
  };

  renderSportExpertRow = (item: SportExpertOrg) => {
    const { sportExpertCompetenceTypeCodelist } = this.props;
    return (
      <Tr key={item._id}>
        <Td>{item.org_profile_name}</Td>
        <Td>
          {item.sport &&
            item.sport_sector &&
            this.getSportSectorLabel(item.sport, item.sport_sector)}
        </Td>
        <Td>{__('Športový odborník')}</Td>
        <Td>
          {item.competence_type &&
            sportExpertCompetenceTypeCodelist[item.competence_type]}
        </Td>
        <Td>{item.date_from && formatDate(item.date_from, 'DD.MM.YYYY')}</Td>
        <Td>{item.date_to && formatDate(item.date_to, 'DD.MM.YYYY')}</Td>
        <Td>{}</Td>
      </Tr>
    );
  };

  render() {
    const { user } = this.props;
    return (
      <Segment raised>
        <Segment noBottomGutter>
          <SegmentHeader>{__('Príslušnosti')}</SegmentHeader>
          <ScrollWrapper>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Organizácia')}</Th>
                  <Th>{__('Šport')}</Th>
                  <Th>{__('Kategória')}</Th>
                  <Th>{__('Typ')}</Th>
                  <Th>{__('Dátum od')}</Th>
                  <Th>{__('Dátum do')}</Th>
                  <Th>{__('Členské do')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {user.sport_orgs && user.sport_orgs.map(this.renderSportOrgRow)}
                {user.sport_expert_orgs &&
                  user.sport_expert_orgs.map(this.renderSportExpertRow)}
              </Tbody>
            </Table>
          </ScrollWrapper>
        </Segment>
      </Segment>
    );
  }
}

export default connect<IMapStateToProps>(mapStateToProps)(UserAffilations);
