import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router';
import { BreadCrumb } from '../../library/App';
import styled from '../../theme/styled-components';

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: ${rem(15)} 0;
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.inactiveColor};
  .last {
    color: ${({ theme }) => theme.textColor};
  }
`;

const BreadCrumbLink = styled(Link)`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
`;

const BreadCrumbText = styled('span')`
  cursor: pointer;
`;

const BreadCrumbSeparator = styled('span')``;

interface OwnProps {
  breadcrumbs: ReadonlyArray<BreadCrumb>;
}

type Props = OwnProps;

class Breadcrumbs extends React.PureComponent<Props> {
  renderBreadcrumbs = (
    crumb: BreadCrumb,
    index: number,
    crumbs: BreadCrumb[],
  ) => {
    const isLast = index === crumbs.length - 1;
    if (crumb.url) {
      return (
        <React.Fragment key={crumb.id}>
          <BreadCrumbLink to={crumb.url} className={isLast ? 'last' : ''}>
            {crumb.name}
          </BreadCrumbLink>
          {!isLast && <BreadCrumbSeparator>&nbsp;/&nbsp;</BreadCrumbSeparator>}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={crumb.id}>
        <BreadCrumbText className={isLast ? 'last' : ''}>
          {crumb.name}
        </BreadCrumbText>
        {!isLast && <BreadCrumbSeparator>&nbsp;/&nbsp;</BreadCrumbSeparator>}
      </React.Fragment>
    );
  };

  render() {
    const { breadcrumbs } = this.props;
    return <Wrapper>{breadcrumbs.map(this.renderBreadcrumbs)}</Wrapper>;
  }
}

export default Breadcrumbs;
