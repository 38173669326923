import * as React from 'react';
import { IndexRoute, Redirect, Route, Router } from 'react-router';
import App from './pages/App';
import Page from './pages/Page';
import AppSpaceResolver from './pages/AppSpaceResolver';
import Contact from './pages/Contact';
import Member from './pages/Member';
import Members from './pages/Members';
import Organizations from './pages/Organizations';
import Team from './pages/Team';
import Teams from './pages/Teams';
import Themer from './pages/Themer';

const Routes: React.SFC<any> = props => {
  return (
    <Router {...props}>
      <Route component={AppSpaceResolver}>
        <Route component={Themer}>
          <Route component={App}>
            <Route component={Page}>
              <IndexRoute component={Contact} />
              <Route path="contact" component={Contact} />
              <Route path="organizations" component={Organizations} />
              <Route path="members/:userId" component={Member} />
              <Route path="members" component={Members} />
              <Route path="teams" component={Teams} />
              <Route path="teams/:teamId" component={Team} />
              <Redirect from="*" to="contact" />
            </Route>
          </Route>
        </Route>
      </Route>
    </Router>
  );
};

export default Routes;
