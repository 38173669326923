import Segment from '@sportnet/ui/lib/Segment';
import * as React from 'react';
import ReadOnlyForm from '../../components/ReadOnlyForm';
import SegmentHeader from '../../components/SegmentHeader';
import { PPOContactInfo } from '../../SportnetApi';
import { __ } from '../../utilities';

type OwnProps = {
  contactInfo: PPOContactInfo;
};

type Props = OwnProps;

class ContactInfo extends React.PureComponent<Props> {
  getWebLink = (url?: string) => {
    if (!url) {
      return '';
    }
    let href = url;
    if (!url.match(/^(http|https):\/\//)) {
      href = `http://${url}`;
    }
    return (
      <a rel="noopener noreferrer" target="_blank" href={href}>
        {url}
      </a>
    );
  };

  render() {
    const { contactInfo } = this.props;
    return (
      <Segment raised>
        <Segment noBottomGutter>
          <SegmentHeader>{__('Kontaktné údaje')}</SegmentHeader>
          <ReadOnlyForm
            fields={[
              {
                label: __('Telefónne číslo'),
                value: contactInfo.phone || '',
                sizes: {
                  m: 3,
                  s: 6,
                  xs: 12,
                },
              },
              {
                label: __('E-mailová adresa'),
                value: contactInfo.email || '',
                sizes: {
                  m: 3,
                  s: 6,
                  xs: 12,
                },
              },
              {
                label: __('Webová stránka'),
                value: this.getWebLink(contactInfo.www),
                sizes: {
                  m: 3,
                  s: 6,
                  xs: 12,
                },
              },
              {
                label: __('Fax'),
                value: contactInfo.fax || '',
                sizes: {
                  m: 3,
                  s: 6,
                  xs: 12,
                },
              },
            ]}
          />
        </Segment>
      </Segment>
    );
  }
}

export default ContactInfo;
