import BasicTable from '@sportnet/ui/lib/BasicTable';
import Header from '@sportnet/ui/lib/Header';
import NotFound from '@sportnet/ui/lib/NotFound';
import connectQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { hasCommitFailed, isCommiting } from 'redux-list';
import { getProp } from 'sportnet-utilities';
import EntryAnimation from '../../components/EntryAnimation';
import Loader from '../../components/Loader';
import ScrollWrapper from '../../components/ScrollWrapper';
import config from '../../config';
import { ReduxConnectProps } from '../../configureStore';
import PPOFilter from '../../containers/Filters/PPOFilter';
import SearchFilter from '../../containers/Filters/SearchFilter';
import ListLayout from '../../containers/ListLayout';
import { RouteProps } from '../../library/App';
import { PPO } from '../../library/PPO';
import { RootState } from '../../rootReducer';
import { getPPOUrl, __ } from '../../utilities';
import {
  initializeOrSetListParams,
  setBreadcrumbs,
  withAppSpace,
} from '../App/actions';
import {
  codelistLabelByValueSelector,
  currentPPOSelector,
} from '../App/selectors';
import { loadPPOList } from './actions';
import { listPPOSelector } from './selectors';

export const LIST_NAME = config.ORGANIZATIONS_NS;

const mapStateToProps = (state: RootState) => ({
  isFetching: isCommiting(LIST_NAME)(state) || false,
  PPOs: listPPOSelector(LIST_NAME)(state),
  hasCommitFailed: hasCommitFailed(LIST_NAME)(state),
  currentPPO: currentPPOSelector(state),
  orgProfileTypeCodelist: codelistLabelByValueSelector(
    'organization-profile-type',
  )(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = {} & IMapStateToProps &
  ReduxConnectProps &
  RouteProps &
  QueryHocInterface;

class Organizations extends React.PureComponent<Props> {
  static setBreadcrumbs(props: Props) {
    const { dispatch } = props;
    return dispatch(
      withAppSpace.action({
        action: setBreadcrumbs,
        callback: (appSpace, currentPPO) => [
          {
            name: getProp(currentPPO, ['name'], '') as string,
            id: 'ROOT',
            url: `/contact`,
          },
          {
            name: __('Zoznam pridružených organizácií'),
            id: 'organizations',
            url: `/organizations`,
          },
        ],
      }),
    );
  }

  static async getInitialProps(props: Props) {
    const { dispatch, query = {} } = props;
    await dispatch(
      initializeOrSetListParams.action({
        listName: LIST_NAME,
        params: query,
      }),
    );
    Organizations.setBreadcrumbs(props);
    await Promise.all([dispatch(loadPPOList())]);
  }

  componentDidMount() {
    Organizations.getInitialProps(this.props);
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.currentPPO._id !== this.props.currentPPO._id) {
      Organizations.getInitialProps(this.props);
    } else if (prevProps.serializedQuery !== this.props.serializedQuery) {
      const { dispatch, query = {} } = this.props;
      await dispatch(
        initializeOrSetListParams.action({
          listName: LIST_NAME,
          params: query,
        }),
      );
      dispatch(loadPPOList());
    }
  }

  handleClickLoad = () => window.location.reload();

  handleClickPPO = (PPO: PPO) => {
    if (typeof window !== 'undefined') {
      const url = `${getPPOUrl(PPO)}/contact`;
      window.location.href = url;
    }
  };

  render() {
    const {
      PPOs,
      hasCommitFailed,
      query: { q, ppoType },
      orgProfileTypeCodelist,
      isFetching,
      setParameter,
      currentPPO,
    } = this.props;
    const title = __('Zoznam pridružených organizácií');
    return (
      <EntryAnimation key={'Organizations'}>
        <Helmet>
          <title>{title}</title>
          <meta
            property="og:url"
            content={`${getPPOUrl(currentPPO)}/organizations`}
          />
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
          {/* <meta name="description" content="" /> */}
          {/* <meta name="twitter:description" content=""> */}
        </Helmet>
        <Header size="xl">{title}</Header>
        <ListLayout
          title={__('Organizácie')}
          primaryFilter={
            <SearchFilter
              setParameter={setParameter}
              q={q as string}
              loading={isFetching}
            />
          }
          secondaryFilters={
            <PPOFilter
              setParameter={setParameter}
              ppoType={ppoType as string}
            />
          }
        >
          {hasCommitFailed ? (
            <NotFound
              title={__('Ups! Nastala chyba pri načítaní')}
              icon="error"
              actionLabel={__('Skúsiť znova')}
              onClickAction={this.handleClickLoad}
            />
          ) : (
            <ScrollWrapper>
              <BasicTable
                columns={[
                  { header: __('Názov organizácie') },
                  { header: __('Typ organizácie') },
                ]}
                onClickRow={this.handleClickPPO}
                rows={PPOs}
                renderRow={(item: PPO) => [
                  item.name || '',
                  <div style={{ minWidth: '300px' }} key={`type-${item._id}`}>
                    {orgProfileTypeCodelist[item.type || ''] || ''}
                  </div>,
                ]}
                rowKey="_id"
              />
            </ScrollWrapper>
          )}
          {isFetching && <Loader />}
        </ListLayout>
      </EntryAnimation>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  connectQueryHoc({
    parameters: {
      ppoType: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      q: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
    },
  }),
)(Organizations);
