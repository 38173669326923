import 'moment/locale/sk';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import configureStore from './configureStore';
import Routes from './routes';
import GlobalStyles from './theme/globalStyles';
import { ThemeProvider } from './theme/styled-components';
import { theme } from './theme/theme';

let state = {};
if (typeof window !== 'undefined') {
  state = (window as any).__REDUX_STATE__;
}
const store = configureStore(state !== '{{SSR_INITIAL_STATE}}' ? state : {});
const history = syncHistoryWithStore(browserHistory, store);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes history={history} />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);

// register();
