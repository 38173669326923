import FormField from '@sportnet/ui/lib/FormField';
import Header from '@sportnet/ui/lib/Header';
import Segment from '@sportnet/ui/lib/Segment';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { PlayerStats } from '../../library/Member';
import { Season } from '../../library/Team';
import { __ } from '../../utilities';
import PlayerStatisticsTable from './table';

const NegativeMargin = styled.div`
  margin-left: -${rem(10)};
`;

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  select {
    min-width: ${rem(100)};
  }
`;

type OwnProps = {
  playerStats: PlayerStats;
  isFetching: boolean;
  suitableSeasons: Season[];
  seasonId: string;
  setSeason: (seasonId: string) => void;
  currentSportStats: Array<{
    _id: string;
    label: string;
  }>;
};

type Props = OwnProps;

class PlayerStatistics extends React.PureComponent<Props> {
  render() {
    const {
      isFetching,
      currentSportStats,
      playerStats,
      suitableSeasons,
      seasonId,
    } = this.props;

    return (
      <Segment raised>
        <Segment noBottomGutter loading={isFetching}>
          <>
            <Filter>
              <Header>{__('Štatistika hráča')}</Header>
              {!!suitableSeasons.length && (
                <FormField
                  type="select"
                  value={seasonId}
                  onChange={(e: any) => {
                    this.props.setSeason(e.target.value);
                  }}
                >
                  {suitableSeasons.map(s => (
                    <option value={s._id}>{s.name}</option>
                  ))}
                </FormField>
              )}
            </Filter>
            {!!Object.keys(playerStats).length && (
              <NegativeMargin>
                <PlayerStatisticsTable
                  title={__('Sezónna štatistika')}
                  stats={playerStats.summary.stats}
                  currentSportStats={currentSportStats}
                />
                {playerStats.byCompetition.map(competition => (
                  <PlayerStatisticsTable
                    collapsed
                    title={competition.name}
                    stats={competition.stats}
                    currentSportStats={currentSportStats}
                  />
                ))}
              </NegativeMargin>
            )}
          </>
        </Segment>
      </Segment>
    );
  }
}

export default PlayerStatistics;
