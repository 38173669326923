import { getListResults } from 'redux-list';
import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import { at } from '../../utilities';
import { detailDomainSelector, entitiesSelector } from '../App/selectors';

export const isFetchingTeamSelector = (id: string) =>
  createSelector(
    detailDomainSelector,
    detailDomain =>
      getProp(detailDomain.teamsById, [id, 'isFetching'], false) as boolean,
  );

export const containsErrorTeamSelector = (id: string) =>
  createSelector(
    detailDomainSelector,
    detailDomain => !!getProp(detailDomain.teamsById, [id, 'error'], false),
  );

export const listTeamCompetitionsSelector = (listName: string) =>
  createSelector(entitiesSelector, getListResults(listName), (entities, ids) =>
    at(entities.COMPETITIONS, ids || []),
  );

export const sportSectorsSettingsSelector = () =>
  createSelector(entitiesSelector, entities => entities.SPORT_SECTOR_SETTINGS);

export const sportSectorsPhasesSelector = () =>
  createSelector(entitiesSelector, entities => entities.SPORT_SECTOR_PHASES);

export const sportSectorsStatsSelector = () =>
  createSelector(entitiesSelector, entities => entities.SPORT_SECTOR_STATS);
