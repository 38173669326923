import { commit, CommitError, getListParameters } from 'redux-list';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import CompetitionsApi from '../../CompetitionsApi';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import { NormalizedEntities } from '../../library/App';
import { RootState } from '../../rootReducer';
import { normalizeEntities } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';

export const LIST_NAME = 'TEAMS_LIST';

const create = actionCreatorFactory(config.TEAMS_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

const loadTeams = createAsync<
  { appSpace: string; seasonId?: string; offset?: number; limit?: number },
  {
    results: string[];
    total: number;
    limit: number;
    offset: number;
    nextOffset?: number | null;
  }
>('GET_TEAMS', async (parameters, dispatch, getState, { SportnetApi }) => {
  const params: { [key: string]: any } = {};

  if (parameters.offset) {
    params.offset = parameters.offset;
  }
  if (parameters.limit) {
    params.limit = parameters.limit;
  }
  if (parameters.seasonId) {
    params.seasonIds = [parameters.seasonId];
  }
  const response = await CompetitionsApi.getTeams(parameters.appSpace, params);

  return {
    ...normalizeEntities('TEAMS', response.teams),
    total: response.total,
    limit: response.limit,
    offset: response.offset,
    nextOffset: response.nextOffset,
  };
});

export const loadTeamsList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const appSpace = activeAppspaceSelector(getState());
    const parameters = getListParameters(LIST_NAME)(getState());

    return dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          try {
            const result = await dispatch(
              loadTeams.action({ ...parameters, appSpace }),
            );
            return {
              total: result.total,
              results: result.results,
            };
          } catch (e) {
            throw new CommitError();
          }
        },
      }),
    );
  };
};

export const loadSeasons = createAsync<
  { playerId?: string },
  NormalizedEntities
>('GET_PPOS', async (parameters, dispatch, getState) => {
  const appSpace = activeAppspaceSelector(getState());
  const filter: { playerId?: string } = {};
  if (parameters.playerId) {
    filter.playerId = parameters.playerId;
  }
  const response = await CompetitionsApi.getClubPlayedSeasons(appSpace, filter);
  return normalizeEntities('SEASONS', response.seasons);
});
