import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import CompetitionsApi from './CompetitionsApi';
import rootReducer, { RootState } from './rootReducer';
import SportnetApi from './SportnetApi';

if (process.env.REACT_APP_SPORTNET_API_BASE_URL) {
  SportnetApi.setBaseUrl(String(process.env.REACT_APP_SPORTNET_API_BASE_URL));
} else {
  throw Error('REACT_APP_SPORTNET_API_BASE_URL not set');
}

if (process.env.REACT_APP_COMPETITIONS_API_BASE_URL) {
  CompetitionsApi.setBaseUrl(
    String(process.env.REACT_APP_COMPETITIONS_API_BASE_URL),
  );
} else {
  throw Error('REACT_APP_COMPETITIONS_API_BASE_URL not set');
}

let composeEnhancers;
if (typeof window !== 'undefined') {
  composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

const extraArgument = { SportnetApi };
export type ExtraArgumentType = typeof extraArgument;
const thunk: ThunkMiddleware<{}, AnyAction> = thunkMiddleware.withExtraArgument(
  extraArgument,
);

const enhancer = composeEnhancers(
  applyMiddleware(routerMiddleware(browserHistory), thunk),
);

export type CustomThunkAction<R> = (
  dispatch: ThunkDispatch<RootState, ExtraArgumentType, AnyAction>,
  getState: () => RootState,
  extraArgument: { SportnetApi: typeof SportnetApi },
) => R;

export type CustomThunkDispatch = ThunkDispatch<
  RootState,
  ExtraArgumentType,
  AnyAction
>;

export interface ReduxConnectProps {
  dispatch: CustomThunkDispatch;
}

export default function configureStore(initialState?: object) {
  return createStore(rootReducer, initialState!, enhancer);
}
