import * as React from 'react';
import styled from '../../theme/styled-components';

const Wrapper = styled('div')`
  width: 100%;
  overflow-x: auto;
`;

class ScrollWrapper extends React.PureComponent<{}> {
  render() {
    return <Wrapper>{this.props.children}</Wrapper>;
  }
}

export default ScrollWrapper;
