import FormField from '@sportnet/ui/lib/FormField';
import * as React from 'react';
import { connect } from 'react-redux';
import { ReduxConnectProps } from '../../configureStore';
import { CodelistItem } from '../../library/App';
import { codelistItemsSelector } from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities';

const mapStateToProps = (state: RootState) => ({
  orgProfileTypeCodelistItems: codelistItemsSelector(
    'organization-profile-type',
  )(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
  setParameter: (parameters: { [key: string]: any }) => void;
  ppoType: string;
}

type Props = OwnProps & IMapStateToProps & ReduxConnectProps;

class PPOFilter extends React.PureComponent<Props> {
  handleChangeParameter = (name: string) => (value: string | number) => {
    this.props.setParameter({ [name]: value });
  };

  handleEventChangeParameter = (name: string) => (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    this.handleChangeParameter(name)((e.target as HTMLInputElement).value);
  };

  renderCodelistOption = (item: CodelistItem) => (
    <option value={item.value} key={item.value}>
      {item.label || item.value}
    </option>
  );

  render() {
    const { ppoType, orgProfileTypeCodelistItems } = this.props;
    return (
      <FormField
        label={__('Typ organizácie')}
        name="ppoType"
        type="select"
        value={ppoType}
        onChange={this.handleEventChangeParameter('ppoType')}
      >
        <option value="" key="all">
          {__('Všetky')}
        </option>
        {orgProfileTypeCodelistItems.map(this.renderCodelistOption)}
      </FormField>
    );
  }
}

export default connect<IMapStateToProps>(mapStateToProps)(PPOFilter);
