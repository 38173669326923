import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { PlayerStat } from '../../library/Member';

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: ${rem(10)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${rem(13)};
  & > ${Item}:nth-child(even) {
    background: #f5f5f5;
  }
`;

const Col = styled.div``;

interface OwnProps {
  stats: PlayerStat;
  title: string;
  currentSportStats: Array<{
    _id: string;
    label: string;
  }>;
  collapsed?: boolean;
}

type Props = OwnProps;

class PlayerStatisticsTable extends React.PureComponent<Props> {
  renderItem = (label: string, value?: string | number) => {
    if (typeof value !== undefined && value !== '' && value !== null) {
      return (
        <Item>
          <Col>{label}</Col>
          <Col>
            <b>{value}</b>
          </Col>
        </Item>
      );
    }
    return null;
  };
  render() {
    const { stats, title, currentSportStats, collapsed } = this.props;
    return (
      <Segment
        noBottomGutter
        header={
          <SegmentHeader
            withSeparator
            collapsible
            isCollapsed={!!collapsed}
            size="s"
          >
            {title}
          </SegmentHeader>
        }
      >
        <Wrapper>
          {currentSportStats.map(stat =>
            this.renderItem(stat.label, (stats as any)[stat._id]),
          )}
        </Wrapper>
      </Segment>
    );
  }
}

export default PlayerStatisticsTable;
