import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ReduxConnectProps } from '../../configureStore';
import { RouteProps, SSRProps } from '../../library/App';
import { RootState } from '../../rootReducer';
import { ThemeProvider } from '../../theme/styled-components';
import { getTheme } from '../../theme/theme';
import { loadPpoTheme } from './actions';
import { activePPOThemeSelector } from './selectors';

const mapStateToProps = (state: RootState) => {
  return {
    theme: activePPOThemeSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = ReduxConnectProps & IMapStateToProps & RouteProps;

class Themer extends React.PureComponent<Props> {
  static async getInitialProps(props: Props & SSRProps) {
    const { dispatch } = props;
    await Promise.all([dispatch(loadPpoTheme.action())]);
  }

  componentDidMount() {
    Themer.getInitialProps(this.props);
  }

  render() {
    const { theme } = this.props;
    return (
      <ThemeProvider theme={getTheme(theme)}>
        <>{this.props.children}</>
      </ThemeProvider>
    );
  }
}

export default compose(connect<IMapStateToProps>(mapStateToProps))(Themer);
