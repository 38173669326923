import { setProp } from 'sportnet-utilities';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ReduxDetailById } from '../../library/App';
import * as actions from './actions';

const MEMBERS_BY_ID_INITIAL_STATE: ReduxDetailById = {};

export const membersByIdDetailReducer = reducerWithInitialState(
  MEMBERS_BY_ID_INITIAL_STATE,
)
  .case(
    actions.loadMember.async.started,
    (state, { userId }): ReduxDetailById =>
      setProp(state, [userId], { isFetching: true }),
  )
  .case(
    actions.loadMember.async.done,
    (state, { params: { userId } }): ReduxDetailById =>
      setProp(state, [userId], { isFetching: false, error: null }),
  )
  .case(
    actions.loadMember.async.failed,
    (state, { params: { userId }, error }): ReduxDetailById =>
      setProp(state, [userId], { isFetching: false, error }),
  );

const PLAYER_STATS_BY_ID_INITIAL_STATE: ReduxDetailById = {};

export const playerStatsByIdDetailReducer = reducerWithInitialState(
  PLAYER_STATS_BY_ID_INITIAL_STATE,
)
  .case(
    actions.loadPlayerStats.async.started,
    (state, { userId }): ReduxDetailById =>
      setProp(state, [userId], { isFetching: true }),
  )
  .case(
    actions.loadPlayerStats.async.done,
    (state, { params: { userId } }): ReduxDetailById =>
      setProp(state, [userId], { isFetching: false, error: null }),
  )
  .case(
    actions.loadPlayerStats.async.failed,
    (state, { params: { userId }, error }): ReduxDetailById =>
      setProp(state, [userId], { isFetching: false, error }),
  );
