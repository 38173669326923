import { getListParameters, initialize, setParams } from 'redux-list';
import { ListParameters } from 'redux-list/lib/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { BreadCrumb, NormalizedEntities } from '../../library/App';
import { PO } from '../../library/PO';
import { PPO } from '../../library/PPO';
import { RootState } from '../../rootReducer';
import { getSportCodelistKey, normalizeEntities } from '../../utilities';
import {
  activeAppspaceSelector,
  currentPOSelector,
  currentPPOSelector,
} from './selectors';

const create = actionCreatorFactory(config.APP_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const setBreadcrumbs = create<BreadCrumb[]>('SET_BREADCRUMBS');

export const setActiveAppspace = create<string>('SET_ACTIVE_APPSPACE');

export const removeActiveAppspace = create('REMOVE_ACTIVE_APPSPACE');

export const withAppSpace = createAsync<
  {
    action: any;
    callback: (appSpace?: string, currentPPO?: PPO) => any;
  },
  any
>('WITH_APPSPACE', async (parameters, dispatch, getState) => {
  const appSpace = activeAppspaceSelector(getState());
  const currentPPO = currentPPOSelector(getState());
  return dispatch(parameters.action(parameters.callback(appSpace, currentPPO)));
});

export const loadPpo = createAsync<
  {
    appSpace: string;
  },
  NormalizedEntities
>('GET_PPO', async (parameters, dispatch, getState, { SportnetApi }) => {
  const ppo = await SportnetApi.organizationPPOProfile(parameters.appSpace);
  return normalizeEntities('PPO', [ppo]);
});

export const loadCodelist = createAsync<
  {
    codelist: string;
  },
  NormalizedEntities
>('GET_CODELIST', async ({ codelist }, dispatch, getState, { SportnetApi }) => {
  const response = await SportnetApi.getCodelist(codelist);
  return normalizeEntities('CODELISTS', [
    { _id: codelist, items: response.codelist },
  ]);
});

export const loadSportWithSectorsCodelist = createAsync<
  void,
  NormalizedEntities
>(
  'GET_CODELIST_WITH_SECTORS',
  async (parameters, dispatch, getState, { SportnetApi }) => {
    const response = await SportnetApi.getCodelist('sport');
    const sectors = await Promise.all(
      (response.codelist || []).map(async codelistItem => {
        const id = getSportCodelistKey(codelistItem.value);
        return {
          _id: id,
          items: (await SportnetApi.getCodelist(id)).codelist,
        };
      }),
    );
    const codelists = [{ _id: 'sport', items: response.codelist }].concat(
      sectors,
    );
    return normalizeEntities('CODELISTS', codelists);
  },
);

export const loadIcons = createAsync<
  void,
  {
    logo: string | null;
    favicon: string | null;
  }
>('GET_ICONS', async (parameters, dispatch, getState) => {
  const currentPPO = currentPPOSelector(getState()) as PPO;
  const currentPO = currentPOSelector(getState()) as PO;
  const logo = currentPPO.logo_public_url || currentPO.logo_public_url || null;
  const favicon =
    currentPPO.favicon_public_url || currentPO.favicon_public_url || null;
  return {
    logo,
    favicon,
  };
});

export const initializeOrSetListParams = createAsync<
  {
    listName: string;
    params: ListParameters;
  },
  any
>('INITIALIZE_OR_SET_LIST_PARAMS', async (parameters, dispatch, getState) => {
  const reduxListParams = getListParameters(parameters.listName)(getState());
  if (Object.keys(reduxListParams).length === 0) {
    return dispatch(
      initialize({
        listName: parameters.listName,
        initialParams: parameters.params,
      }),
    );
  }
  return dispatch(
    setParams({
      listName: parameters.listName,
      parameters: parameters.params,
    }),
  );
});

export const loadPOInvoiceProfile = createAsync<void, NormalizedEntities>(
  'GET_PO_INVOICE_PROFILE',
  async (parameters, dispatch, getState, { SportnetApi }) => {
    const currentPPO = currentPPOSelector(getState());
    const invoiceProfile = await SportnetApi.organizationInvoiceProfile(
      currentPPO.organization_id || '',
    );
    return normalizeEntities('PO', [invoiceProfile]);
  },
);
