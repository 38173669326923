import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ReduxConnectProps } from '../../configureStore';
import Loading from '../../containers/Loading';
import { RootState } from '../../rootReducer';
import { getAppSpace } from '../../utilities';
import {
  loadIcons,
  loadPOInvoiceProfile,
  loadPpo,
  setActiveAppspace,
} from '../App/actions';
import {
  activeAppspaceSelector,
  currentPPOSelector,
  isFetchingCurrentPPOSelector,
} from '../App/selectors';
import NotFound from '../NotFound';

const mapStateToProps = (state: RootState) => {
  return {
    currentPPO: currentPPOSelector(state),
    isFetching: isFetchingCurrentPPOSelector(state),
    appSpace: activeAppspaceSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  hostname?: string;
};

type Props = OwnProps & IMapStateToProps & ReduxConnectProps;

class AppSpaceResolver extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    const appSpace = getAppSpace(props);
    await dispatch(setActiveAppspace(appSpace));
    await dispatch(loadPpo.action({ appSpace }));
    await dispatch(loadPOInvoiceProfile.action());
    await dispatch(loadIcons.action());
  }

  componentDidMount() {
    AppSpaceResolver.getInitialProps(this.props);
  }

  render() {
    const { currentPPO, isFetching, appSpace } = this.props;

    const isPPOLoaded = Object.keys(currentPPO).length > 0;

    if (isFetching && !isPPOLoaded) {
      return <Loading />;
    }

    if (!isPPOLoaded || !appSpace) {
      return <NotFound />;
    }

    return this.props.children;
  }
}

export default compose(connect<IMapStateToProps>(mapStateToProps))(
  AppSpaceResolver,
);
