import * as React from 'react';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs';
import MaxWidthBox from '../../components/MaxWidthBox';
import { ReduxConnectProps } from '../../configureStore';
import { breadcrumbsSelector } from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';
import styled from '../../theme/styled-components';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = (state: RootState) => ({
  breadcrumbs: breadcrumbsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = IMapStateToProps & ReduxConnectProps;

class Layout extends React.PureComponent<Props> {
  render() {
    const { breadcrumbs } = this.props;
    return (
      <MaxWidthBox>
        <Wrapper>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          {this.props.children}
        </Wrapper>
      </MaxWidthBox>
    );
  }
}

export default connect<IMapStateToProps>(mapStateToProps)(Layout);
