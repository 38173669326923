import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import SportnetLogoLight from '../../assets/sportnet-logo-light.svg';
import MaxWidthBox from '../../components/MaxWidthBox';
import { ReduxConnectProps } from '../../configureStore';
import { RootState } from '../../rootReducer';
import styled from '../../theme/styled-components';
import { __ } from '../../utilities';

const Wrapper = styled('div')`
  display: flex;
  background-color: white;
  height: ${rem(80)};
  align-items: center;
  margin-top: auto;
`;

const Content = styled('div')`
  display: flex;
`;

const Logo = styled('img')`
  height: ${rem(40)};
  margin-left: auto;
  display: block;
`;

const mapStateToProps = (state: RootState) => ({});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = {} & IMapStateToProps & ReduxConnectProps;

class Footer extends React.PureComponent<Props> {
  render() {
    return (
      <Wrapper>
        <MaxWidthBox>
          <Content>
            <Logo alt={__('Logo Sportnet online')} src={SportnetLogoLight} />
          </Content>
        </MaxWidthBox>
      </Wrapper>
    );
  }
}

export default connect<IMapStateToProps>(mapStateToProps)(Footer);
