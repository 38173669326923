import { commit, CommitError, getListParameters } from 'redux-list';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import { NormalizedEntities, Pager } from '../../library/App';
import { RootState } from '../../rootReducer';
import { normalizeEntities } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';
import { LIST_NAME } from './';

const create = actionCreatorFactory(config.MEMBERS_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

interface Parameters {
  limit?: number;
  offset?: number;
  q?: string;
  affiliationDateFrom?: string;
  affiliationDateTo?: string;
  affiliationCategory?: string;
  competenceType?: string;
}

const loadMembers = createAsync<
  Parameters & { appSpace: string },
  NormalizedEntities & Pager
>('GET_MEMBERS', async (parameters, dispatch, getState, { SportnetApi }) => {
  const params: Parameters & {
    affiliationCategory?: 'sport_orgs' | 'sport_expert_orgs';
  } = {
    limit: config.DEFAULT_LIST_LIMIT,
    offset: 0,
  };
  if (parameters.limit) {
    params.limit = parameters.limit;
  }
  if (parameters.offset) {
    params.offset = parameters.offset;
  }
  if (parameters.q) {
    params.q = parameters.q;
  }
  if (parameters.affiliationDateFrom) {
    params.affiliationDateFrom = parameters.affiliationDateFrom;
  }
  if (parameters.affiliationDateTo) {
    params.affiliationDateTo = parameters.affiliationDateTo;
  }
  if (
    parameters.affiliationCategory === 'sport_orgs' ||
    parameters.affiliationCategory === 'sport_expert_orgs'
  ) {
    params.affiliationCategory = parameters.affiliationCategory;
  }
  if (parameters.competenceType) {
    params.competenceType = parameters.competenceType;
  }
  const response = await SportnetApi.organizationPPOUsers(
    parameters.appSpace,
    params,
  );
  return {
    ...normalizeEntities('MEMBERS', response.users!),
    total: response.total!,
    limit: response.limit!,
    offset: response.offset!,
    nextOffset: response.next_offset,
  };
});

export const loadMembersList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState, { SportnetApi }) => {
    const appSpace = activeAppspaceSelector(getState());
    const parameters = getListParameters(LIST_NAME)(getState());
    return dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          try {
            const {
              results,
              limit,
              nextOffset,
              offset,
              total,
            } = await dispatch(loadMembers.action({ ...parameters, appSpace }));
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e) {
            throw new CommitError();
          }
        },
      }),
    );
  };
};
