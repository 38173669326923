import LoaderDefault from '@sportnet/ui/lib/Loader';
import * as React from 'react';
import EntryAnimation from '../../components/EntryAnimation';
import { Wrapper } from '../../pages/App';
import styled from '../../theme/styled-components';
import Footer from '../Footer';

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

class Loading extends React.PureComponent<{}> {
  render() {
    return (
      <Wrapper>
        <ContentWrapper>
          <EntryAnimation key={'loading'}>
            <LoaderDefault size="xl" />
          </EntryAnimation>
        </ContentWrapper>
        <Footer />
      </Wrapper>
    );
  }
}

export default Loading;
