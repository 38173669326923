import { setProp } from 'sportnet-utilities';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ReduxDetailById } from '../../library/App';
import * as actions from './actions';

const TEAMS_BY_ID_INITIAL_STATE: ReduxDetailById = {};

export const teamsByIdDetailReducer = reducerWithInitialState(
  TEAMS_BY_ID_INITIAL_STATE,
)
  .case(
    actions.loadTeamById.async.started,
    (state, { teamId }): ReduxDetailById =>
      setProp(state, [teamId], { isFetching: true }),
  )
  .case(
    actions.loadTeamById.async.done,
    (state, { params: { teamId } }): ReduxDetailById =>
      setProp(state, [teamId], { isFetching: false, error: null }),
  )
  .case(
    actions.loadTeamById.async.failed,
    (state, { params: { teamId }, error }): ReduxDetailById =>
      setProp(state, [teamId], { isFetching: false, error }),
  );
