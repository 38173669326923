import BasicTable from '@sportnet/ui/lib/BasicTable';
import FormField from '@sportnet/ui/lib/FormField';
import Header from '@sportnet/ui/lib/Header';
import NotFound from '@sportnet/ui/lib/NotFound';
import Paginator from '@sportnet/ui/lib/Paginator';
import { isAfter, isBefore } from 'date-fns';
import connectToQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { getListTotal, hasCommitFailed, isCommiting } from 'redux-list';
import { getProp } from 'sportnet-utilities';
import EntryAnimation from '../../components/EntryAnimation';
import Loader from '../../components/Loader';
import ScrollWrapper from '../../components/ScrollWrapper';
import { ReduxConnectProps } from '../../configureStore';
import ListLayout from '../../containers/ListLayout';
import { RouteProps } from '../../library/App';
import { Team } from '../../library/Team';
import { RootState } from '../../rootReducer';
import { getPPOUrl, __ } from '../../utilities';
import {
  initializeOrSetListParams,
  setBreadcrumbs,
  withAppSpace,
} from '../App/actions';
import { currentPPOSelector } from '../App/selectors';
import { LIST_NAME, loadSeasons, loadTeamsList } from './actions';
import { listTeamsSelector, suitableSeasonsSelector } from './selectors';

const LIST_LIMIT = 20;

const mapStateToProps = (state: RootState) => ({
  currentPPO: currentPPOSelector(state),
  teams: listTeamsSelector(LIST_NAME)(state),
  isFetching: isCommiting(LIST_NAME)(state),
  fetchFailed: hasCommitFailed(LIST_NAME)(state),
  total: getListTotal(LIST_NAME)(state),
  suitableSeasons: suitableSeasonsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = ReduxConnectProps &
  IMapStateToProps &
  QueryHocInterface &
  RouteProps;

class Teams extends React.PureComponent<Props> {
  static setBreadcrumbs(props: Props) {
    const { dispatch } = props;
    return dispatch(
      withAppSpace.action({
        action: setBreadcrumbs,
        callback: (appSpace, currentPPO) => [
          {
            name: getProp(currentPPO, ['name'], '') as string,
            id: 'ROOT',
            url: `/contact`,
          },
          {
            name: __('Zoznam tímov'),
            id: 'teams',
            url: `/teams`,
          },
        ],
      }),
    );
  }

  static async getInitialProps(props: Props) {
    const { dispatch, query } = props;
    let seasonId;
    const {
      entities: { SEASONS },
    } = await dispatch(loadSeasons.action({}));
    const seasons = Object.keys(SEASONS || {}).map(
      (k: string) => (SEASONS || {})[k],
    );
    const mostRecentSeason = seasons.find(
      season =>
        isBefore(new Date(season.dateFrom), new Date()) &&
        isAfter(new Date(season.dateTo), new Date()),
    );
    if (mostRecentSeason) {
      seasonId = mostRecentSeason._id;
    } else {
      const sortedSeasons = seasons.sort((a, b) => {
        if (isAfter(new Date(a.dateTo), new Date(b.dateTo))) {
          return -1;
        }
        return 1;
      });
      if (
        sortedSeasons.length > 1 &&
        isAfter(new Date(sortedSeasons[0].dateFrom), new Date())
      ) {
        seasonId = sortedSeasons[1]._id;
      } else {
        seasonId = sortedSeasons[0]._id;
      }
    }
    if (props.setParameter && !query.seasonId) {
      props.setParameter({ seasonId });
    }
    await Promise.all([
      dispatch(
        initializeOrSetListParams.action({
          listName: LIST_NAME,
          params: { ...query, seasonId: query.seasonId || seasonId },
        }),
      ),
      dispatch(loadTeamsList()),
    ]);
    Teams.setBreadcrumbs(props);
  }

  componentDidMount() {
    Teams.getInitialProps(this.props);
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.currentPPO._id !== this.props.currentPPO._id) {
      Teams.getInitialProps(this.props);
    }
    if (prevProps.serializedQuery !== this.props.serializedQuery) {
      const { dispatch, query = {} } = this.props;
      await dispatch(
        initializeOrSetListParams.action({
          listName: LIST_NAME,
          params: query,
        }),
      );
      dispatch(loadTeamsList());
    }
  }

  handleChangeOffset = (offset = 0) => {
    this.props.setParameter({ offset });
  };

  setParameter: Props['setParameter'] = params => {
    this.props.setParameter({ ...params, offset: 0 });
  };

  handleClickTeam = (team: Team) => {
    this.props.router.push({
      pathname: `${this.props.location.pathname}/${team._id}`,
    });
  };

  render() {
    const {
      currentPPO,
      teams,
      isFetching,
      fetchFailed,
      total,
      suitableSeasons,
      query: { offset, seasonId },
    } = this.props;

    const title = `${currentPPO.name} - ${__('tímy')}`;

    return (
      <EntryAnimation key={'Teams'}>
        <Helmet>
          <title>{title}</title>
          <meta property="og:url" content={`${getPPOUrl(currentPPO)}/teams`} />
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </Helmet>
        <Header size="xl">{title}</Header>
        <ListLayout
          title={__('Tímy')}
          primaryFilter={
            <FormField
              type="select"
              value={String(seasonId)}
              onChange={(e: any) => {
                this.setParameter({
                  seasonId: e.target.value,
                });
              }}
            >
              {suitableSeasons.map(season => (
                <option value={season._id}>{season.name}</option>
              ))}
            </FormField>
          }
        >
          {fetchFailed ? (
            <NotFound
              title={__('Ups! Nastala chyba pri načítaní')}
              icon="error"
              actionLabel={__('Skúsiť znova')}
              onClickAction={() => {
                //
              }}
            />
          ) : (
            <ScrollWrapper>
              <BasicTable
                columns={[{ header: __('Názov tímu') }]}
                onClickRow={this.handleClickTeam}
                rows={teams}
                renderRow={(item: Team) => [`${item.displayName}`]}
                rowKey="_id"
              />
              {teams.length > 0 && (
                <Paginator
                  limit={LIST_LIMIT}
                  offset={offset || 0}
                  total={total || 0}
                  onChangeOffset={this.handleChangeOffset}
                />
              )}
            </ScrollWrapper>
          )}
          {!!isFetching && !teams.length && <Loader />}
        </ListLayout>
      </EntryAnimation>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  connectToQueryHoc({
    parameters: {
      offset: {
        type: QueryHocTypes.Number,
        defaultValue: 0,
      },
      seasonId: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      limit: {
        type: QueryHocTypes.Number,
        defaultValue: LIST_LIMIT,
      },
    },
  }),
  withRouter,
)(Teams);
