import Col from '@sportnet/ui/lib/Grid';
import Header from '@sportnet/ui/lib/Header';
import Icon from '@sportnet/ui/lib/Icon';
import { ContentLoader } from '@sportnet/ui/lib/Loader';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { em } from 'polished';
import * as React from 'react';
import Slider from 'react-slick';
import styled, { withTheme } from 'styled-components';
import { ISportSectorPhases, ISportSectorSetting } from '../../library/App';
import { IMatch } from '../../library/Match';
import theme from '../../theme/theme';
import { __ } from '../../utilities';
import Match from '../Match';
import { Dot, Dots } from './Paging';

interface OwnProps {
  matches: IMatch[];
  isFetching: boolean | null;
  sportSectorsPhases: { [key: string]: ISportSectorPhases };
  sportSectorsSettings: { [key: string]: ISportSectorSetting };
}

type Props = OwnProps & {
  theme: typeof theme & {
    sizes: {
      [key: string]: number;
    };
  };
};

const Wrapper = styled.div`
  .slick-slide > div {
    display: flex;
  }
`;

export const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  div {
    color: ${({ theme }) => theme.inactiveColor};
    padding-top: ${em(10)};
    font-weight: lighter;
  }
`;

export const StyledDots = styled(Dots)`
  padding-top: ${({ theme }) => em(theme.grid.gutterWidth)} !important;
`;

class MatchesList extends React.PureComponent<Props> {
  renderSliderChildren = () =>
    this.props.matches.map((match, idx) => (
      <Col key={match._id} xs={12} m={6}>
        <Match
          settings={{
            ...this.props.sportSectorsSettings[match.rules.sport_sector],
            ...match.settings,
          }}
          theme={this.props.theme}
          match={match}
          fullWidth={false}
          lastIndex={(idx + 1) % 2 === 0}
          sportSectorsPhases={this.props.sportSectorsPhases}
        />
      </Col>
    ));

  render() {
    const { isFetching, theme } = this.props;
    const sliderSettings = {
      infinite: false,
      slidesToShow: 1,
      slidesPerRow: 2,
      rows: 4,
      dots: true,
      autoplay: false,
      arrows: false,
      speed: 500,
      swipeToSlide: true,
      appendDots: (dots: any) => {
        return <StyledDots>{dots}</StyledDots>;
      },
      customPaging: (i: number) => <Dot active={true} />,
      responsive: [
        {
          breakpoint: theme.sizes.m,
          settings: {
            slidesToShow: 1,
            slidesPerRow: 1,
            rows: 8,
          },
        },
      ],
    };
    return (
      <Segment
        header={
          <Header>
            <SegmentHeader size="s" withSeparator>
              {__('Stretnutia')}
            </SegmentHeader>
          </Header>
        }
      >
        {!!isFetching ? (
          <ContentLoader size="xl" theme={theme} />
        ) : !!this.props.matches.length ? (
          <Wrapper>
            <Slider {...sliderSettings}>{this.renderSliderChildren()}</Slider>
          </Wrapper>
        ) : (
          <NotFound>
            <Icon name="info" size="l" color={this.props.theme.inactiveColor} />
            <div>{__('Nenašli sa žiadne stretnutia tímu')}</div>
          </NotFound>
        )}
      </Segment>
    );
  }
}

export default withTheme(MatchesList);
