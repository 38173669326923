import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { NormalizedEntities } from '../../library/App';
import { RootState } from '../../rootReducer';
import { normalizeEntities } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';

const create = actionCreatorFactory(config.THEMER_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadPpoTheme = createAsync<void, NormalizedEntities>(
  'GET_PPO_THEME',
  async (parameters, dispatch, getState, { SportnetApi }) => {
    const appSpace = activeAppspaceSelector(getState());
    const theme = await SportnetApi.organizationPPOTheme(appSpace);
    return normalizeEntities('PPO', [{ theme, _id: appSpace }]);
  },
);
