import DefaultHeader from '@sportnet/ui/lib/Header';
import Separator from '@sportnet/ui/lib/Separator';
import { rem } from 'polished';
import * as React from 'react';
import styled from '../../theme/styled-components';

const HeaderWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
  & > div {
    margin: 0 0 ${rem(8)} 0;
  }
`;

type Props = {
  size?: string;
  className?: string;
  children: any;
  icon?: any;
  subtitle?: string;
  iconSize?: string;
  withSeparator?: boolean;
};

class SegmentHeader extends React.PureComponent<Props> {
  render() {
    return (
      <>
        <HeaderWrapper>
          <DefaultHeader {...this.props} />
        </HeaderWrapper>
        <Separator />
      </>
    );
  }
}

export default SegmentHeader;
