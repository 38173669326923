import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as listReducer } from 'redux-list';
import {
  applicationReducer,
  entitiesReducer,
  pposByIdDetailReducer,
} from './pages/App/reducer';
import {
  membersByIdDetailReducer,
  playerStatsByIdDetailReducer,
} from './pages/Member/reducer';
import { teamsByIdDetailReducer } from './pages/Team/reducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  application: applicationReducer,
  entities: entitiesReducer,
  list: listReducer,
  detail: combineReducers({
    pposById: pposByIdDetailReducer,
    memberById: membersByIdDetailReducer,
    playerStatsById: playerStatsByIdDetailReducer,
    teamsById: teamsByIdDetailReducer,
  }),
});

export default rootReducer;

export type RootState = {
  routing: ReturnType<typeof routerReducer>;
  application: ReturnType<typeof applicationReducer>;
  entities: ReturnType<typeof entitiesReducer>;
  list: ReturnType<typeof listReducer>;
  detail: {
    pposById: ReturnType<typeof pposByIdDetailReducer>;
    memberById: ReturnType<typeof membersByIdDetailReducer>;
    playerStatsById: ReturnType<typeof playerStatsByIdDetailReducer>;
    teamsById: ReturnType<typeof teamsByIdDetailReducer>;
  };
};
