import BasicTable from '@sportnet/ui/lib/BasicTable';
import Header from '@sportnet/ui/lib/Header';
import NotFound from '@sportnet/ui/lib/NotFound';
import Paginator from '@sportnet/ui/lib/Paginator';
import connectQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getListTotal, hasCommitFailed, isCommiting } from 'redux-list';
import { getProp } from 'sportnet-utilities';
import EntryAnimation from '../../components/EntryAnimation';
import Loader from '../../components/Loader';
import ScrollWrapper from '../../components/ScrollWrapper';
import config from '../../config';
import { ReduxConnectProps } from '../../configureStore';
import MembersFilter from '../../containers/Filters/MembersFilter';
import SearchFilter from '../../containers/Filters/SearchFilter';
import ListLayout from '../../containers/ListLayout';
import { RouteProps } from '../../library/App';
import { Member } from '../../library/Member';
import { RootState } from '../../rootReducer';
import { getPPOUrl, __ } from '../../utilities';
import {
  initializeOrSetListParams,
  setBreadcrumbs,
  withAppSpace,
} from '../App/actions';
import { currentPPOSelector } from '../App/selectors';
import { loadMembersList } from './actions';
import { listMembersSelector } from './selectors';

export const LIST_NAME = config.MEMBERS_NS;

const mapStateToProps = (state: RootState) => ({
  isFetching: isCommiting(LIST_NAME)(state) || false,
  total: getListTotal(LIST_NAME)(state),
  members: listMembersSelector(LIST_NAME)(state),
  hasCommitFailed: hasCommitFailed(LIST_NAME)(state),
  currentPPO: currentPPOSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = {} & IMapStateToProps &
  ReduxConnectProps &
  RouteProps &
  QueryHocInterface;

class Members extends React.PureComponent<Props> {
  static setBreadcrumbs(props: Props) {
    const { dispatch } = props;
    return dispatch(
      withAppSpace.action({
        action: setBreadcrumbs,
        callback: (appSpace, currentPPO) => [
          {
            name: getProp(currentPPO, ['name'], '') as string,
            id: 'ROOT',
            url: `/contact`,
          },
          {
            name: __('Zoznam členov'),
            id: 'members',
            url: `/members`,
          },
        ],
      }),
    );
  }

  static async getInitialProps(props: Props) {
    const { dispatch, query = {} } = props;
    await dispatch(
      initializeOrSetListParams.action({
        listName: LIST_NAME,
        params: query,
      }),
    );
    Members.setBreadcrumbs(props);
    await Promise.all([dispatch(loadMembersList())]);
  }

  componentDidMount() {
    Members.getInitialProps(this.props);
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.currentPPO._id !== this.props.currentPPO._id) {
      Members.getInitialProps(this.props);
    } else if (prevProps.serializedQuery !== this.props.serializedQuery) {
      const { dispatch, query = {} } = this.props;
      await dispatch(
        initializeOrSetListParams.action({
          listName: LIST_NAME,
          params: query,
        }),
      );
      dispatch(loadMembersList());
    }
  }

  handleClickLoad = () => window.location.reload();

  handleClickMember = (member: Member) => {
    this.props.router.push({
      pathname: `${this.props.location.pathname}/${member._id}`,
      search: this.props.location.search,
    });
  };

  handleChangeOffset = (offset = 0) => {
    this.props.setParameter({ offset });
  };

  setParameter: Props['setParameter'] = params => {
    // if parameter changed => reset paginator
    this.props.setParameter({ ...params, offset: 0 });
  };

  render() {
    const {
      members,
      hasCommitFailed,
      query: {
        affiliationCategory,
        competenceType,
        affiliationDateFrom,
        affiliationDateTo,
        q,
        offset,
        limit,
      },
      total,
      isFetching,
      currentPPO,
    } = this.props;
    const title = __('Zoznam členov');
    return (
      <EntryAnimation key={'Members'}>
        <Helmet>
          <title>{title}</title>
          <meta
            property="og:url"
            content={`${getPPOUrl(currentPPO)}/members`}
          />
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
          {/* <meta name="description" content="" /> */}
          {/* <meta name="twitter:description" content=""> */}
        </Helmet>
        <Header size="xl">{title}</Header>
        <ListLayout
          title={__('Členovia')}
          primaryFilter={
            <SearchFilter
              setParameter={this.setParameter}
              q={q as string}
              loading={isFetching}
            />
          }
          secondaryFilters={
            <MembersFilter
              setParameter={this.setParameter}
              affiliationCategory={affiliationCategory as string}
              competenceType={competenceType as string}
              affiliationDateFrom={affiliationDateFrom as string}
              affiliationDateTo={affiliationDateTo as string}
            />
          }
        >
          {hasCommitFailed ? (
            <NotFound
              title={__('Ups! Nastala chyba pri načítaní')}
              icon="error"
              actionLabel={__('Skúsiť znova')}
              onClickAction={this.handleClickLoad}
            />
          ) : (
            <ScrollWrapper>
              <BasicTable
                columns={[
                  { header: __('Meno a priezvisko') },
                  { header: __('Registračné číslo') },
                  { header: __('Materský klub') },
                  { header: __('Členom od') },
                ]}
                onClickRow={this.handleClickMember}
                rows={members}
                renderRow={(item: Member) => [
                  `${item.name} ${item.surname}`,
                  getProp(item, ['membership', 'regnr']),
                  '',
                  '',
                ]}
                rowKey="_id"
              />
              {members.length > 0 && (
                <Paginator
                  step={20}
                  limit={limit || 0}
                  offset={offset || 0}
                  total={total || 0}
                  onChangeOffset={this.handleChangeOffset}
                />
              )}
            </ScrollWrapper>
          )}
          {!!isFetching && <Loader />}
        </ListLayout>
      </EntryAnimation>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  connectQueryHoc({
    parameters: {
      affiliationCategory: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      competenceType: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      affiliationDateFrom: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      affiliationDateTo: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      q: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      offset: {
        type: QueryHocTypes.Number,
        defaultValue: 0,
      },
      limit: {
        type: QueryHocTypes.Number,
        defaultValue: config.DEFAULT_LIST_LIMIT,
      },
    },
  }),
)(Members);
