import Col, { Row } from '@sportnet/ui/lib/Grid';
import Image from '@sportnet/ui/lib/Image';
import Segment from '@sportnet/ui/lib/Segment';
import { rem } from 'polished';
import * as React from 'react';
import { getProp } from 'sportnet-utilities';
import ReadOnlyForm from '../../components/ReadOnlyForm';
import SegmentHeader from '../../components/SegmentHeader';
import { Member } from '../../library/Member';
import styled from '../../theme/styled-components';
import { getUserPhotoSrc, __ } from '../../utilities';

const PhotoCenterWrapper = styled('div')`
  margin: 0 ${rem(5)} ${rem(25)} ${rem(5)};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => (theme as any).media.m`
    justify-content: flex-start;
    margin: 0 ${rem(5)} 0 ${rem(5)};
  `}
`;

const PhotoWrapper = styled('div')`
  position: relative;
  height: ${rem(190)};
  width: ${rem(190)};
  border-radius: 50%;
  overflow: hidden;
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

type Props = {
  user: Member;
};

class UserBusinessCard extends React.PureComponent<Props> {
  render() {
    const { user } = this.props;
    return (
      <Segment raised>
        <Segment noBottomGutter>
          <SegmentHeader>{__('Vizitka')}</SegmentHeader>
          <Row>
            <Col s={12} m={4}>
              <PhotoCenterWrapper>
                <PhotoWrapper>
                  <StyledImage
                    src={getUserPhotoSrc(
                      user._id || '',
                      getProp(user, ['photo', 'token'], '') as string,
                    )}
                    height="100%"
                  />
                </PhotoWrapper>
              </PhotoCenterWrapper>
            </Col>
            <Col s={12} m={8}>
              <ReadOnlyForm
                fields={[
                  {
                    label: __('Meno a priezvisko'),
                    value:
                      user.name &&
                      user.surname &&
                      `${user.name} ${user.surname}`,
                    sizes: {
                      m: 4,
                      s: 6,
                      xs: 12,
                    },
                  },
                  {
                    label: __('Rok narodenia'),
                    value: '',
                    sizes: {
                      m: 4,
                      s: 6,
                      xs: 12,
                    },
                  },
                  {
                    label: __('Národnosť'),
                    value: '',
                    sizes: {
                      m: 4,
                      s: 6,
                      xs: 12,
                    },
                  },
                  {
                    label: __('Registračné číslo'),
                    value: getProp(user, ['membership', 'regnr'], '') as string,
                    sizes: {
                      m: 4,
                      s: 6,
                      xs: 12,
                    },
                  },
                  {
                    label: __('Platnosť členstva od'),
                    value: '',
                    sizes: {
                      m: 4,
                      s: 6,
                      xs: 12,
                    },
                  },
                  {
                    label: __('Platnosť členstva do'),
                    value: '',
                    sizes: {
                      m: 4,
                      s: 6,
                      xs: 12,
                    },
                  },
                  {
                    label: __('Materský klub'),
                    value: '',
                    sizes: {
                      m: 4,
                      s: 6,
                      xs: 12,
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        </Segment>
      </Segment>
    );
  }
}

export default UserBusinessCard;
