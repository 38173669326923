import { AnyAction } from 'redux';
import { getProp, setProp } from 'sportnet-utilities';
import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';
import {
  BreadCrumb,
  Codelist,
  ISportSectorPhases,
  ISportSectorSetting,
  ReduxDetailById,
} from '../../library/App';
import { Competition } from '../../library/Competition';
import { Member, PlayerStats } from '../../library/Member';
import { PO } from '../../library/PO';
import { PPO } from '../../library/PPO';
import { Season, Team } from '../../library/Team';
import * as actions from './actions';

export type EntitiesState = Readonly<{
  PPO: Readonly<{
    [key: string]: Readonly<PPO>;
  }>;
  PO: Readonly<{
    [key: string]: Readonly<PO>;
  }>;
  MEMBERS: Readonly<{
    [key: string]: Readonly<Member>;
  }>;
  PLAYER_STATS: Readonly<{
    [key: string]: Readonly<PlayerStats>;
  }>;
  TEAMS: Readonly<{
    [key: string]: Readonly<Team>;
  }>;
  COMPETITIONS: Readonly<{
    [key: string]: Readonly<Competition>;
  }>;
  SEASONS: Readonly<{
    [key: string]: Readonly<Season>;
  }>;
  CODELISTS: Readonly<{
    [key: string]: Readonly<Codelist>;
  }>;
  SPORT_SECTOR_PHASES: Readonly<{
    [key: string]: Readonly<ISportSectorPhases>;
  }>;
  SPORT_SECTOR_STATS: Readonly<{
    [key: string]: Readonly<{
      _id: string;
      items: Array<{
        _id: string;
        label: string;
      }>;
    }>;
  }>;
  SPORT_SECTOR_SETTINGS: Readonly<{
    [key: string]: Readonly<ISportSectorSetting>;
  }>;
}>;

type Writeable<T> = { -readonly [P in keyof T]-?: T[P] };

export const entitiesReducer = (
  state: EntitiesState = {
    PPO: {},
    PO: {},
    MEMBERS: {},
    PLAYER_STATS: {},
    CODELISTS: {},
    TEAMS: {},
    SEASONS: {},
    COMPETITIONS: {},
    SPORT_SECTOR_PHASES: {},
    SPORT_SECTOR_STATS: {},
    SPORT_SECTOR_SETTINGS: {},
  },
  action: AnyAction,
) => {
  if (getProp(action.payload, ['result', 'entities'])) {
    return Object.keys(action.payload.result.entities).reduce(
      (acc: any, entity: any) => {
        acc[entity] = Object.keys(
          action.payload.result.entities[entity],
        ).reduce(
          (innerAcc: Writeable<EntitiesState[keyof EntitiesState]>, id) => {
            innerAcc[id] = {
              ...innerAcc[id],
              ...action.payload.result.entities[entity][id],
            };
            return innerAcc;
          },
          { ...getProp(state, [entity], {}) },
        );
        return acc;
      },
      { ...state },
    );
  }
  return state;
};

export type ApplicationState = Readonly<{
  activeAppspace: string | null;
  breadcrumbs: ReadonlyArray<BreadCrumb>;
  logo: string | null;
  favicon: string | null;
}>;

const APPLICATION_INITIAL_STATE: ApplicationState = {
  activeAppspace: null,
  breadcrumbs: [],
  logo: null,
  favicon: null,
};

export const applicationReducer = reducerWithInitialState(
  APPLICATION_INITIAL_STATE,
)
  .case(
    actions.setActiveAppspace,
    (state, activeAppspace): ApplicationState => ({
      ...state,
      activeAppspace,
    }),
  )
  .case(
    actions.removeActiveAppspace,
    (state): ApplicationState => ({
      ...state,
      activeAppspace: null,
    }),
  )
  .case(
    actions.setBreadcrumbs,
    (state, breadcrumbs): ApplicationState => ({
      ...state,
      breadcrumbs,
    }),
  )
  .case(
    actions.loadIcons.async.done,
    (state, { result: { favicon, logo } }): ApplicationState => ({
      ...state,
      logo,
      favicon,
    }),
  );

const PPOS_BY_ID_INITIAL_STATE: ReduxDetailById = {};

export const pposByIdDetailReducer = reducerWithInitialState(
  PPOS_BY_ID_INITIAL_STATE,
)
  .case(
    actions.loadPpo.async.started,
    (state, { appSpace }): ReduxDetailById =>
      setProp(state, [appSpace], { isFetching: true }),
  )
  .case(
    actions.loadPpo.async.done,
    (state, { params: { appSpace } }): ReduxDetailById =>
      setProp(state, [appSpace], { isFetching: false, error: null }),
  )
  .case(
    actions.loadPpo.async.failed,
    (state, { params: { appSpace }, error }): ReduxDetailById =>
      setProp(state, [appSpace], { isFetching: false, error }),
  );
