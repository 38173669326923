import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import { detailDomainSelector } from '../App/selectors';

export const isFetchingMemberSelector = (id: string) =>
  createSelector(
    detailDomainSelector,
    detailDomain =>
      getProp(detailDomain.memberById, [id, 'isFetching'], false) as boolean,
  );

export const isFetchingPlayerStatsSelector = (id: string) =>
  createSelector(
    detailDomainSelector,
    detailDomain =>
      getProp(
        detailDomain.playerStatsById,
        [id, 'isFetching'],
        false,
      ) as boolean,
  );

export const containsErrorMemberSelector = (id: string) =>
  createSelector(
    detailDomainSelector,
    detailDomain => !!getProp(detailDomain.memberById, [id, 'error'], false),
  );
