import Header from '@sportnet/ui/lib/Header';
import * as React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import ContactInfo from '../../components/ContactInfo';
import EntryAnimation from '../../components/EntryAnimation';
import InvoiceInfo from '../../components/InvoiceInfo';
import { ReduxConnectProps } from '../../configureStore';
import { BreadCrumb, RouteProps } from '../../library/App';
import { RootState } from '../../rootReducer';
import { getPPOUrl, __ } from '../../utilities';
import { setBreadcrumbs, withAppSpace } from '../App/actions';
import { currentPOSelector, currentPPOSelector } from '../App/selectors';
import { loadPpoContactInfo } from './actions';

const mapStateToProps = (state: RootState) => ({
  currentPPO: currentPPOSelector(state),
  currentPO: currentPOSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = {} & IMapStateToProps & ReduxConnectProps & RouteProps;

class Contact extends React.PureComponent<Props> {
  static setBreadcrumbs(props: Props) {
    const { dispatch } = props;
    return dispatch(
      withAppSpace.action({
        action: setBreadcrumbs,
        callback: (appSpace, currentPPO): BreadCrumb[] => [
          {
            name: getProp(currentPPO, ['name'], '') as string,
            id: 'ROOT',
            url: `/contact`,
          },
          {
            name: __('Kontakt'),
            id: 'contact',
            url: `/contact`,
          },
        ],
      }),
    );
  }

  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    Contact.setBreadcrumbs(props);
    await Promise.all([dispatch(loadPpoContactInfo.action())]);
  }

  componentDidMount() {
    Contact.getInitialProps(this.props);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.currentPPO._id !== this.props.currentPPO._id) {
      Contact.getInitialProps(this.props);
    }
  }

  render() {
    const { currentPPO, currentPO } = this.props;
    const contactInfo = currentPPO.contactInfo || {};
    const title = currentPPO.name || __('Kontakt');
    return (
      <EntryAnimation key={'Contact'}>
        <Helmet titleTemplate="%s">
          <title>{title}</title>
          <meta
            property="og:url"
            content={`${getPPOUrl(currentPPO)}/contact`}
          />
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={title} />
          {/* <meta name="description" content="" /> */}
          {/* <meta name="twitter:description" content=""> */}
        </Helmet>
        <Header size="xl">{title}</Header>
        <InvoiceInfo PPO={currentPPO} PO={currentPO} />
        <ContactInfo contactInfo={contactInfo} />
      </EntryAnimation>
    );
  }
}

export default compose(connect<IMapStateToProps>(mapStateToProps))(Contact);
