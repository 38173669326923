import Icon from '@sportnet/ui/lib/Icon';
import Image from '@sportnet/ui/lib/Image';
import ResponsiveMenu from '@sportnet/ui/lib/ResponsiveMenu';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import { compose } from 'redux';
import MaxWidthBox from '../../components/MaxWidthBox';
import { ReduxConnectProps } from '../../configureStore';
import { RouteProps } from '../../library/App';
import { currentPPOSelector, logoSrcSelector } from '../../pages/App/selectors';
import { RootState } from '../../rootReducer';
import styled, { css } from '../../theme/styled-components';
import { idealTextColor, __ } from '../../utilities';

const Wrapper = styled('div')`
  display: flex;
  background-color: white;
  box-shadow: 0 0 ${rem(12)} 0 rgba(0, 0, 0, 0.3);
  height: ${rem(80)};
`;

const LogoLink = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: ${rem(56)};
`;

const InnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
`;

const BurgerWrapper = styled('div')`
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
  flex-shrink: 0;
  ${({ theme }) => (theme as any).media.m`
    display: none;
  `}
`;

const Menu = styled('div')`
  display: none;
  ${({ theme }) => (theme as any).media.m`
    display: flex;
  `}
`;

const MenuItem = styled(Link)`
  text-decoration: none;
  text-align: center;
  padding: ${rem(8)} ${rem(8)};
  margin-right: ${rem(15)};
  display: block;
  cursor: pointer;
  color: inherit;
  min-width: ${rem(110)};
  &:hover {
    ${({ theme }) =>
      css`
        color: ${theme.color.primary};
        background-color: white;
      `}
  }
  &.active {
    ${({ theme }) =>
      css`
        background-color: ${theme.color.primary};
        color: ${idealTextColor(theme.color.primary)};
      `}
  }
  transition: all 0.2s ease-out;
`;

const mapStateToProps = (state: RootState) => ({
  logoSrc: logoSrcSelector(state),
  currentPPO: currentPPOSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = IMapStateToProps & ReduxConnectProps & RouteProps;

type Section = {
  name: string;
  _id: string;
  url: string;
};

type State = {
  menuOpened: boolean;
  sections: Section[];
};

class Header extends React.PureComponent<Props, State> {
  defaultSections = [
    {
      name: __('Organizácie'),
      _id: 'organizations',
      url: `/organizations`,
    },
    {
      name: __('Členovia'),
      _id: 'members',
      url: `/members`,
    },
    {
      name: __('Kontakt'),
      _id: 'contact',
      url: `/contact`,
    },
  ];

  state = {
    menuOpened: false,
    sections: [],
  };

  componentDidMount() {
    this.toggleMenuItems();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.currentPPO._id !== nextProps.currentPPO._id) {
      this.toggleMenuItems(nextProps);
    }
  }

  toggleMenuItems = (props = this.props) => {
    if (props.currentPPO.type === 'club') {
      this.setState({
        sections: [
          {
            name: __('Tímy'),
            _id: 'teams',
            url: '/teams',
          },
          ...this.defaultSections,
        ],
      });
    } else {
      this.setState({
        sections: this.defaultSections,
      });
    }
  };

  handleMenuOpen = () => {
    this.setState({
      menuOpened: true,
    });
  };

  handleMenuClose = () => {
    this.setState({
      menuOpened: false,
    });
  };

  handleLinkClick = (section: Section) => {
    this.props.router.push(section.url);
    this.handleMenuClose();
  };

  renderMenu = (section: Section) => {
    const active = this.props.location.pathname.includes(section._id);
    return (
      <MenuItem
        key={section._id}
        to={section.url}
        className={active ? 'active' : ''}
      >
        {section.name}
      </MenuItem>
    );
  };

  render() {
    const { logoSrc, currentPPO } = this.props;
    const { sections, menuOpened } = this.state;
    return (
      <Wrapper>
        <MaxWidthBox>
          <InnerWrapper>
            {logoSrc && (
              <LogoLink to={`/contact`}>
                <Image alt={currentPPO.name || ''} src={logoSrc} height={40} />
              </LogoLink>
            )}
            <BurgerWrapper onClick={this.handleMenuOpen}>
              <Icon name="burger" size="xl" />
            </BurgerWrapper>
            <Menu>{sections.map(this.renderMenu)}</Menu>
            <ResponsiveMenu
              sections={sections}
              onClose={this.handleMenuClose}
              onClickLink={this.handleLinkClick}
              visible={menuOpened}
              siteUrl={currentPPO.name || ''}
            />
          </InnerWrapper>
        </MaxWidthBox>
      </Wrapper>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps))(Header);
