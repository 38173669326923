import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import CompetitionsApi from '../../CompetitionsApi';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { NormalizedEntities } from '../../library/App';
import { RootState } from '../../rootReducer';
import { normalizeEntities } from '../../utilities';
import { activeAppspaceSelector } from '../App/selectors';

const create = actionCreatorFactory(config.MEMBER_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadMember = createAsync<
  {
    userId: string;
  },
  NormalizedEntities
>('GET_MEMBER', async (parameters, dispatch, getState, { SportnetApi }) => {
  const appSpace = activeAppspaceSelector(getState());
  const member = await SportnetApi.organizationPPOUser(
    appSpace,
    parameters.userId,
  );
  return normalizeEntities('MEMBERS', [member]);
});

export const loadPlayerStats = createAsync<
  { userId: string; seasonId?: string },
  NormalizedEntities
>('GET_PLAYER_STATS', async (parameters, dispatch, getState) => {
  const appSpace = activeAppspaceSelector(getState());
  const filter: { [key: string]: string } = {};
  if (parameters.seasonId) {
    filter.seasonId = parameters.seasonId;
  }
  const playerStats = await CompetitionsApi.getPlayerStats(
    appSpace,
    parameters.userId,
    filter,
  );
  return normalizeEntities('PLAYER_STATS', [
    {
      _id: parameters.userId,
      ...playerStats,
    },
  ]);
});
