import { getListParameters, getListResults } from 'redux-list';
import { createSelector } from 'reselect';
import { removeAccents } from 'sportnet-utilities';
import { at } from '../../utilities';
import { entitiesSelector } from '../App/selectors';

const unfilteredlistPPOSelector = (listName: string) =>
  createSelector(entitiesSelector, getListResults(listName), (entities, ids) =>
    at(entities.PPO, ids || []),
  );

const listPPOQParameterSelector = (listName: string) =>
  createSelector(
    getListParameters(listName),
    params => (params.q || '') as string,
  );

export const listPPOSelector = (listName: string) =>
  createSelector(
    unfilteredlistPPOSelector(listName),
    listPPOQParameterSelector(listName),
    (PPOs, q) => {
      const regex = RegExp(removeAccents(q).trim(), 'i');
      return PPOs.filter(
        (PPO: any) => PPO.name && removeAccents(PPO.name).match(regex),
      );
    },
  );
