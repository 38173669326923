import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import { CodelistLabelByValue } from '../../library/App';
import { RootState } from '../../rootReducer';

// Entities
export const entitiesSelector = (state: RootState) => {
  return state.entities;
};

export const entitiesCodelistsSelector = createSelector(
  entitiesSelector,
  entities => entities.CODELISTS || {},
);

export const entityPPOSelector = (id: string) =>
  createSelector(entitiesSelector, entities => entities.PPO[id] || {});

export const entityPOSelector = (id: string) =>
  createSelector(entitiesSelector, entities => entities.PO[id] || {});

export const entityMemberSelector = (id: string) =>
  createSelector(entitiesSelector, entities => entities.MEMBERS[id] || {});

export const entityPlayerStatsSelector = (id: string) =>
  createSelector(entitiesSelector, entities => entities.PLAYER_STATS[id] || {});

export const entityTeamSelector = (id: string) =>
  createSelector(entitiesSelector, entities => entities.TEAMS[id] || {});

export const entityCodelistSelector = (id: string) =>
  createSelector(entitiesSelector, entities => entities.CODELISTS[id] || {});

export const codelistItemsSelector = (id: string) =>
  createSelector(entityCodelistSelector(id), codelist => codelist.items || []);

const applicationDomainSelector = (state: RootState) => {
  return state.application;
};

export const activeAppspaceSelector = createSelector(
  applicationDomainSelector,
  substate => substate.activeAppspace || '',
);

export const currentPPOSelector = createSelector(
  entitiesSelector,
  activeAppspaceSelector,
  (entities, id) => {
    if (id) {
      return entities.PPO[id] || {};
    }
    return {};
  },
);

export const currentPOSelector = createSelector(
  entitiesSelector,
  currentPPOSelector,
  (entities, PPO) => {
    const POId = PPO.organization_id;
    if (POId) {
      return entities.PO[POId] || {};
    }
    return {};
  },
);

export const breadcrumbsSelector = createSelector(
  applicationDomainSelector,
  substate => substate.breadcrumbs,
);

export const logoSrcSelector = createSelector(
  applicationDomainSelector,
  substate => substate.logo,
);

export const faviconSrcSelector = createSelector(
  applicationDomainSelector,
  substate => substate.favicon,
);

export const detailDomainSelector = (state: RootState) => state.detail;

export const codelistLabelByValueSelector = (id: string) =>
  createSelector(codelistItemsSelector(id), codelistItems => {
    return codelistItems.reduce(
      (acc: CodelistLabelByValue, codelistItem: any) => {
        acc[codelistItem.value] = codelistItem.label || codelistItem.value;
        return acc;
      },
      {},
    );
  });

export const isFetchingCurrentPPOSelector = createSelector(
  detailDomainSelector,
  activeAppspaceSelector,
  (detailDomain, appSpace) =>
    getProp(detailDomain.pposById, [appSpace, 'isFetching'], false) as boolean,
);
