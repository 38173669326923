import Header from '@sportnet/ui/lib/Header';
import { isAfter, isBefore } from 'date-fns';
import connectToQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getListParameters } from 'redux-list';
import { getProp } from 'sportnet-utilities';
import EntryAnimation from '../../components/EntryAnimation';
import UserAffilations from '../../components/UserAffilations';
import UserBusinessCard from '../../components/UserBusinessCard';
import { ReduxConnectProps } from '../../configureStore';
import PlayerStatistics from '../../containers/PlayerStatistics';
import { RouteProps } from '../../library/App';
import { Member as User } from '../../library/Member';
import { RootState } from '../../rootReducer';
import { getPPOUrl, __ } from '../../utilities';
import {
  initializeOrSetListParams,
  setBreadcrumbs,
  withAppSpace,
} from '../App/actions';
import {
  currentPPOSelector,
  entityMemberSelector,
  entityPlayerStatsSelector,
} from '../App/selectors';
import { getSportSectorsStats } from '../Team/actions';
import { sportSectorsStatsSelector } from '../Team/selectors';
import { loadSeasons } from '../Teams/actions';
import { suitableSeasonsSelector } from '../Teams/selectors';
import { loadMember, loadPlayerStats } from './actions';
import { isFetchingPlayerStatsSelector } from './selectors';

export const PLAYER_STATS_LIST_NAME = 'PLAYER_STATS_LIST';

const mapStateToProps = (
  state: RootState,
  props: RouteProps<{ userId: string }>,
) => {
  const {
    params: { userId },
  } = props;
  return {
    currentPPO: currentPPOSelector(state),
    currentUser: entityMemberSelector(userId)(state),
    playerStats: entityPlayerStatsSelector(userId)(state),
    playerStatsIsFetching: isFetchingPlayerStatsSelector(userId)(state),
    suitableSeasons: suitableSeasonsSelector(state),
    listParameters: getListParameters(PLAYER_STATS_LIST_NAME)(state),
    sportSectorsStats: sportSectorsStatsSelector()(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = {} & IMapStateToProps &
  ReduxConnectProps &
  RouteProps<{ userId: string }> &
  QueryHocInterface;

class Member extends React.PureComponent<Props> {
  static setBreadcrumbs(props: Props, user: User) {
    const { dispatch } = props;
    const userName =
      user && user.name && user.surname && `${user.name} ${user.surname}`;
    return dispatch(
      withAppSpace.action({
        action: setBreadcrumbs,
        callback: (appSpace, currentPPO) => [
          {
            name: getProp(currentPPO, ['name'], '') as string,
            id: 'ROOT',
            url: `/contact`,
          },
          {
            name: __('Zoznam členov'),
            id: 'members',
            url: `/members`,
          },
          {
            name: userName || '',
            id: 'userId',
            url: `/members/${user._id || ''}`,
          },
        ],
      }),
    );
  }

  static async getPlayerStatistics(props: Props) {
    const {
      dispatch,
      params: { userId },
    } = props;
    try {
      let seasonId = props.query.seasonId;
      const {
        entities: { SEASONS },
      } = await dispatch(loadSeasons.action({ playerId: userId }));
      const seasons = Object.keys(SEASONS || {}).map(
        (k: string) => (SEASONS || {})[k],
      );
      if (!seasonId) {
        const mostRecentSeason = seasons.find(
          season =>
            isBefore(new Date(season.dateFrom), new Date()) &&
            isAfter(new Date(season.dateTo), new Date()),
        );
        if (mostRecentSeason) {
          seasonId = mostRecentSeason._id;
        } else {
          const sortedSeasons = seasons.sort((a, b) => {
            if (isAfter(new Date(a.dateTo), new Date(b.dateTo))) {
              return -1;
            }
            return 1;
          });
          if (
            sortedSeasons.length > 1 &&
            isAfter(new Date(sortedSeasons[0].dateFrom), new Date())
          ) {
            seasonId = sortedSeasons[1]._id;
          } else {
            seasonId = sortedSeasons[0]._id;
          }
        }
      }
      await dispatch(
        initializeOrSetListParams.action({
          listName: PLAYER_STATS_LIST_NAME,
          params: {
            seasonId,
          },
        }),
      );
      await Promise.all([
        dispatch(
          loadPlayerStats.action({
            userId,
            seasonId: String(seasonId),
          }),
        ),
        dispatch(getSportSectorsStats.action()),
      ]);
    } catch (e) {
      // silent
    }
  }

  static async getInitialProps(props: Props) {
    const {
      dispatch,
      params: { userId },
    } = props;
    const result = await dispatch(loadMember.action({ userId }));
    const user = getProp(
      result,
      ['entities', 'MEMBERS', result.results[0]],
      {},
    ) as User;
    await Member.getPlayerStatistics(props);
    Member.setBreadcrumbs(props, user);
  }

  componentDidMount() {
    Member.getInitialProps(this.props);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.currentPPO._id !== this.props.currentPPO._id ||
      prevProps.currentUser._id !== this.props.currentUser._id
    ) {
      Member.getInitialProps(this.props);
    }
    if (prevProps.query.seasonId !== this.props.query.seasonId) {
      Member.getPlayerStatistics(this.props);
    }
  }

  render() {
    const {
      currentUser,
      currentPPO,
      playerStats,
      playerStatsIsFetching,
      suitableSeasons,
      listParameters,
      sportSectorsStats,
      params: { userId },
    } = this.props;

    let currentSportStats: Array<{
      _id: string;
      label: string;
    }> = [];
    if (currentPPO) {
      currentSportStats = sportSectorsStats[currentPPO.sport || '']
        ? sportSectorsStats[currentPPO.sport || ''].items
        : [];
    }

    const title =
      (currentUser.name &&
        currentUser.surname &&
        `${currentUser.name} ${currentUser.surname}`) ||
      __('Člen');
    return (
      <EntryAnimation key={'Member'}>
        <Helmet>
          <title>{title}</title>
          <meta
            property="og:url"
            content={`${getPPOUrl(currentPPO)}/members/${userId}`}
          />
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
          {/* <meta name="description" content="" /> */}
          {/* <meta name="twitter:description" content=""> */}
        </Helmet>
        <Header size="xl">
          {currentUser.name &&
            currentUser.surname &&
            `${currentUser.name} ${currentUser.surname}`}
        </Header>
        <UserBusinessCard user={currentUser} />
        <UserAffilations user={currentUser} />
        {(!!playerStatsIsFetching || Object.keys(playerStats).length > 0) && (
          <PlayerStatistics
            isFetching={!!playerStatsIsFetching}
            playerStats={playerStats}
            suitableSeasons={suitableSeasons}
            currentSportStats={currentSportStats}
            seasonId={String(listParameters.seasonId)}
            setSeason={(seasonId: string) => {
              this.props.setParameter({
                seasonId,
              });
            }}
          />
        )}
      </EntryAnimation>
    );
  }
}

export default compose(
  connect<IMapStateToProps>(mapStateToProps),
  connectToQueryHoc({
    parameters: {
      seasonId: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
    },
  }),
)(Member);
