import Button from '@sportnet/ui/lib/Button';
import Header from '@sportnet/ui/lib/Header';
import Segment from '@sportnet/ui/lib/Segment';
import Separator from '@sportnet/ui/lib/Separator';
import Sticky from '@sportnet/ui/lib/Sticky';
import { rem } from 'polished';
import * as React from 'react';
import { Collapse } from 'react-collapse';
import { compose } from 'redux';
import SegmentHeader from '../../components/SegmentHeader';
import styled, { css, withTheme } from '../../theme/styled-components';
import { ThemeInterface } from '../../theme/theme';
import { __ } from '../../utilities';

const SIDEBAR_WIDTH = 270;

const SegmentHeaderWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  ${({ theme }) => (theme as any).media.s`
    flex-direction: row;
  `}
`;

const Content = styled('div')`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  ${({ theme }) => (theme as any).media.m`
    flex-direction: row;
  `}
`;

const Sidebar = styled('aside')<{ opened: boolean }>`
  display: none;
  flex-direction: column;
  flex: 1 0 ${rem(SIDEBAR_WIDTH)};
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  max-width: 0;
  margin-right: 0;
  ${({ opened, theme }) =>
    opened &&
    css`
      overflow: visible;
      max-width: ${rem(SIDEBAR_WIDTH)};
      margin-right: ${rem(theme.grid.gutterWidth)};
      & > div:nth-child(2) {
        flex-grow: 1;
      }
    `}
  ${({ theme }) => (theme as any).media.m`
    display: flex;
  `}
`;

const MobileSidebar = styled('aside')`
  display: flex;
  flex-direction: column;
  ${({ theme }) => (theme as any).media.m`
    display: none;
  `}
`;

const Main = styled('main')`
  flex-grow: 1;
`;

const HeaderWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
  & > div {
    margin: 0 0 ${rem(8)} 0;
  }
`;

const FilterButton = styled(Button)`
  background: ${({ theme }) => theme.buttonBackgroundColor};
`;

const ActionsWrapper = styled('div')`
  display: flex;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: ${({ theme }) => rem(theme.grid.gutterWidth)};
  }
  ${({ theme }) => (theme as any).media.s`
    & > * {
      margin-left: ${rem(theme.grid.gutterWidth)};
    }
  `}
`;

type ThemeProps = {
  theme: ThemeInterface;
};

type OwnProps = {
  title: string;
  primaryFilter: React.ReactNode;
  secondaryFilters?: React.ReactNode;
  children: any;
};

type Props = OwnProps & ThemeProps;

type State = {
  sidebarOpened: boolean;
};

class ListLayout extends React.PureComponent<Props, State> {
  state = {
    sidebarOpened: false,
  };

  scrollToMobileFilterIfOpened = () => {
    if (!this.state.sidebarOpened) {
      return;
    }
    if (typeof document !== 'undefined') {
      const filter = document.getElementById('mobile-filter');
      if (filter) {
        filter.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  handleSidebarToggle = () => {
    this.setState(
      prevState => ({
        sidebarOpened: !prevState.sidebarOpened,
      }),
      this.scrollToMobileFilterIfOpened,
    );
  };

  render() {
    const {
      theme,
      title,
      primaryFilter,
      secondaryFilters,
      children,
    } = this.props;
    const { sidebarOpened } = this.state;
    return (
      <Content>
        {!!secondaryFilters && (
          <>
            <Sidebar opened={sidebarOpened}>
              <Sticky offset={theme.grid.gutterWidth} active={sidebarOpened}>
                <Segment raised>
                  <Segment noBottomGutter>
                    <SegmentHeader>{__('Filter')}</SegmentHeader>
                    {secondaryFilters}
                  </Segment>
                </Segment>
              </Sticky>
            </Sidebar>
            <MobileSidebar id="mobile-filter">
              <Collapse
                isOpened={sidebarOpened}
                springConfig={{ stiffness: 240, damping: 40 }}
              >
                <Segment raised>
                  <Segment noBottomGutter>
                    <SegmentHeader>{__('Filter')}</SegmentHeader>
                    {secondaryFilters}
                  </Segment>
                </Segment>
              </Collapse>
            </MobileSidebar>
          </>
        )}
        <Main>
          <Segment raised>
            <Segment>
              <SegmentHeaderWrapper>
                <HeaderWrapper>
                  <Header size="m">{title}</Header>
                </HeaderWrapper>
                <ActionsWrapper>
                  {primaryFilter}
                  {!!secondaryFilters && (
                    <FilterButton
                      icon="filter"
                      onClick={this.handleSidebarToggle}
                    />
                  )}
                </ActionsWrapper>
              </SegmentHeaderWrapper>
              <Separator />
              {children}
            </Segment>
          </Segment>
        </Main>
      </Content>
    );
  }
}

export default compose(withTheme)(ListLayout);
