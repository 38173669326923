import { rem } from 'polished';
import * as React from 'react';
import styled from '../../theme/styled-components';

const Wrapper = styled('div')`
  padding: 0 ${rem(15)};
  max-width: ${rem(1100)};
  margin: 0 auto;
  width: 100%;
  flex: 1 0 0;
`;

class MaxWidthBox extends React.PureComponent<{}> {
  render() {
    return <Wrapper>{this.props.children}</Wrapper>;
  }
}

export default MaxWidthBox;
