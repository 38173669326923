import BasicTable from '@sportnet/ui/lib/BasicTable';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import Header from '@sportnet/ui/lib/Header';
import Icon from '@sportnet/ui/lib/Icon';
import Image from '@sportnet/ui/lib/Image';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import Tabber from '@sportnet/ui/lib/Tabber';
import { rem } from 'polished';
import connectToQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import styled, { withTheme } from 'styled-components';
import EntryAnimation from '../../components/EntryAnimation';
import Loader from '../../components/Loader';
import { ReduxConnectProps } from '../../configureStore';
import MatchesList, { NotFound } from '../../containers/MatchesList';
import { RouteProps } from '../../library/App';
import { Team } from '../../library/Team';
import { RootState } from '../../rootReducer';
import theme from '../../theme/theme';
import { getPPOUrl, __ } from '../../utilities';
import {
  initializeOrSetListParams,
  setBreadcrumbs,
  withAppSpace,
} from '../App/actions';
import { currentPPOSelector, entityTeamSelector } from '../App/selectors';
import {
  getSportSectorsPhases,
  getSportSectorsSettings,
  getTeamCompetitionDetail,
  loadTeamById,
  loadTeamCompetitions,
  loadTeamCompetitionsList,
  TEAM_COMPETITIONS_LIST_NAME,
} from './actions';
import {
  isFetchingTeamSelector,
  listTeamCompetitionsSelector,
  sportSectorsPhasesSelector,
  sportSectorsSettingsSelector,
} from './selectors';

const TeamLogo = styled.img`
  height: ${rem(20)};
`;

const PlayerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${rem(5)} 0;
  pointer-events: none;
`;

const PlayerInfo = styled.div`
  padding: ${rem(10)} ${rem(20)};
  font-size: ${rem(13)};
`;

const PlayerNr = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  right: 0;
  border-radius: 100%;
  font-size: ${rem(11)};
  width: ${rem(20)};
  height: ${rem(20)};
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  display: flex;
`;

type Props = ReduxConnectProps &
  RouteProps<{ teamId: string }> &
  QueryHocInterface & { theme: typeof theme };

const mapStateToProps = (state: RootState, props: Props) => ({
  currentPPO: currentPPOSelector(state),
  team: entityTeamSelector(props.params.teamId)(state),
  competitions: listTeamCompetitionsSelector(TEAM_COMPETITIONS_LIST_NAME)(
    state,
  ),
  isFetching: isFetchingTeamSelector(props.params.teamId)(state),
  sportSectorsPhases: sportSectorsPhasesSelector()(state),
  sportSectorsSettings: sportSectorsSettingsSelector()(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

class TeamDetail extends React.PureComponent<Props & IMapStateToProps> {
  static setBreadcrumbs(props: Props, team: Team) {
    const { dispatch } = props;
    return dispatch(
      withAppSpace.action({
        action: setBreadcrumbs,
        callback: (appSpace, currentPPO) => [
          {
            name: getProp(currentPPO, ['name'], '') as string,
            id: 'ROOT',
            url: `/contact`,
          },
          {
            name: __('Zoznam tímov'),
            id: 'teams',
            url: `/teams`,
          },
          {
            name: team.displayName,
            id: 'teamId',
            url: `/teams/${team._id}`,
          },
        ],
      }),
    );
  }

  static async getInitialProps(props: Props) {
    const {
      dispatch,
      query,
      params: { teamId },
    } = props;
    const results = await dispatch(loadTeamById.action({ teamId }));
    const team = getProp(
      results,
      ['entities', 'TEAMS', results.results[0]],
      {},
    ) as Team;
    await dispatch(
      initializeOrSetListParams.action({
        listName: TEAM_COMPETITIONS_LIST_NAME,
        params: { teamId: team._id },
      }),
    );
    await dispatch(loadTeamCompetitionsList());
    const {
      entities: { COMPETITIONS },
    } = await dispatch(loadTeamCompetitions.action({ teamId }));
    const activeCompetitionId = Object.keys(COMPETITIONS || {})[0];

    if (activeCompetitionId) {
      await Promise.all([
        dispatch(
          getTeamCompetitionDetail.action({
            teamId,
            competitionId: String(query.competitionId) || activeCompetitionId,
          }),
        ),
        dispatch(getSportSectorsPhases.action()),
        dispatch(
          getSportSectorsSettings.action({
            settingSubId: 'competitionPartSettings',
          }),
        ),
      ]);
    }
    TeamDetail.setBreadcrumbs(props, team);
  }

  state = {
    isFetching: false,
  };

  async componentDidMount() {
    this.setState({
      isFetching: true,
    });
    await TeamDetail.getInitialProps(this.props);
    this.setState({
      isFetching: false,
    });
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.query.competitionId !== prevProps.query.competitionId) {
      this.setState({
        isFetching: true,
      });
      await this.getCompetitionDetail(this.props);
      this.setState({
        isFetching: false,
      });
    }
  }

  getCompetitionDetail = async (props: Props) => {
    await props.dispatch(
      getTeamCompetitionDetail.action({
        teamId: props.params.teamId,
        competitionId: String(props.query.competitionId),
      }),
    );
  };

  setActiveCompetition = (competitionName: string) => {
    const competition: any = this.props.competitions.find(
      (c: any) => c.name === competitionName,
    );
    if (competition) {
      this.props.setParameter({
        competitionId: competition._id,
      });
    }
  };

  getPlayerPosition = (value: string) => {
    switch (value) {
      case 'goalkeeper':
        return __('Brankár');
      case 'defender':
        return __('Obranca');
      case 'midfielder':
        return __('Záložník');
      case 'forward':
        return __('Útočník');
      default:
        return '';
    }
  };

  render() {
    const {
      currentPPO,
      team,
      isFetching,
      competitions,
      sportSectorsPhases,
      sportSectorsSettings,
      query: { competitionId },
    } = this.props;
    const title = team.displayName;

    let activeCompetition: any = !!competitions.length && competitions[0];
    if (competitionId) {
      const competition = competitions.find(
        (c: any) => c._id === competitionId,
      );
      if (competition) {
        activeCompetition = competition;
      }
    }

    const results = (activeCompetition && activeCompetition.resultsTable
      ? activeCompetition.resultsTable.results
      : []
    ).map((r: any) => ({
      ...r,
      team:
        !!activeCompetition && !!activeCompetition.teams
          ? activeCompetition.teams.find((t: any) => t._id === r.team._id)
          : r.team,
    }));
    return (
      <EntryAnimation key={'Team'}>
        <Helmet>
          <title>{title}</title>
          <meta property="og:url" content={`${getPPOUrl(currentPPO)}/teams`} />
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </Helmet>
        <Header size="xl">{title}</Header>
        {!!isFetching ? (
          <Loader />
        ) : (
          <>
            <Tabber
              items={competitions.map((c: any) => c.name)}
              onClickItem={this.setActiveCompetition}
              active={activeCompetition ? activeCompetition.name : ''}
            >
              <Segment raised>
                {this.state.isFetching ? (
                  <Loader />
                ) : (
                  <>
                    {!!results.length && (
                      <Segment
                        header={
                          <SegmentHeader size="s" withSeparator>
                            {__('Aktuálny stav')}
                          </SegmentHeader>
                        }
                      >
                        <BasicTable
                          columns={[
                            {
                              header: '',
                              width: 30,
                            },
                            {
                              header: '',
                              width: 30,
                            },
                            {
                              header: __('Klub'),
                            },
                            {
                              header: __('Z'),
                              width: 30,
                            },
                            {
                              header: __('V'),
                              width: 30,
                            },
                            {
                              header: __('R'),
                              width: 30,
                            },
                            {
                              header: __('P'),
                              width: 30,
                            },
                            {
                              header: __('Skóre'),
                              width: 60,
                            },
                            {
                              header: __('B'),
                              width: 30,
                            },
                          ]}
                          rows={results}
                          rowKey="_id"
                          renderRow={(result, idx) => [
                            idx + 1,
                            <TeamLogo
                              title={result.team.displayName}
                              alt={result.team.displayName}
                              key={`logo_${result.team._id}`}
                              src={
                                result.team.organization.logo_public_url || ''
                              }
                            />,
                            result.team.displayName,
                            result.stats.matches.played,
                            result.stats.matches.won,
                            result.stats.matches.draw,
                            result.stats.matches.lost,
                            `${result.stats.goals.given}:${result.stats.goals.received}`,
                            result.stats.points,
                          ]}
                        />
                      </Segment>
                    )}
                    <MatchesList
                      matches={
                        activeCompetition ? activeCompetition.matches || [] : []
                      }
                      isFetching={false}
                      sportSectorsPhases={sportSectorsPhases}
                      sportSectorsSettings={sportSectorsSettings}
                    />
                    <Segment
                      header={
                        <SegmentHeader size="s" withSeparator>
                          {__('Hráči')}
                        </SegmentHeader>
                      }
                    >
                      <Row>
                        {!!activeCompetition &&
                        !!(activeCompetition.players || []).length ? (
                          (activeCompetition.players || []).map(
                            (player: any) => (
                              <Col xs={12} s={6} m={3}>
                                <Link
                                  to={`/members/${player.sportnetUser._id}`}
                                >
                                  <PlayerWrapper>
                                    <div style={{ position: 'relative' }}>
                                      <Image
                                        alt={player.sportnetUser.name}
                                        height={50}
                                        width={50}
                                        src={`https://api.sportnet.online/v1/user-photo/${player.sportnetUser._id}.jpg`}
                                      />
                                      {player.additionalData.nr && (
                                        <PlayerNr>
                                          {player.additionalData.nr}
                                        </PlayerNr>
                                      )}
                                    </div>
                                    <PlayerInfo>
                                      <div>
                                        <b>{player.sportnetUser.name}</b>
                                      </div>
                                      {player.additionalData.position && (
                                        <div>
                                          {this.getPlayerPosition(
                                            player.additionalData.position,
                                          )}
                                        </div>
                                      )}
                                    </PlayerInfo>
                                  </PlayerWrapper>
                                </Link>
                              </Col>
                            ),
                          )
                        ) : (
                          <NotFound>
                            <Icon
                              name="info"
                              size="l"
                              color={this.props.theme.inactiveColor}
                            />
                            <div>{__('Nenašli sa žiadny hráči tímu')}</div>
                          </NotFound>
                        )}
                      </Row>
                    </Segment>
                  </>
                )}
              </Segment>
            </Tabber>
          </>
        )}
      </EntryAnimation>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  connectToQueryHoc({
    parameters: {
      competitionId: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
    },
  }),
  withRouter,
  withTheme,
)(TeamDetail);
