import * as React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ReduxConnectProps } from '../../configureStore';
import Footer from '../../containers/Footer';
import Header from '../../containers/Header';
import { RouteProps } from '../../library/App';
import { RootState } from '../../rootReducer';
import styled from '../../theme/styled-components';
import { getPPOUrl, __ } from '../../utilities';
import { loadCodelist, loadSportWithSectorsCodelist } from './actions';
import {
  currentPPOSelector,
  faviconSrcSelector,
  logoSrcSelector,
} from './selectors';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

export const Content = styled('div')`
  display: flex;
`;

const mapStateToProps = (state: RootState) => ({
  currentPPO: currentPPOSelector(state),
  logo: logoSrcSelector(state),
  favicon: faviconSrcSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = {} & IMapStateToProps & ReduxConnectProps & RouteProps;

class App extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    await Promise.all([
      dispatch(loadCodelist.action({ codelist: 'country' })),
      dispatch(loadSportWithSectorsCodelist.action()),
      dispatch(loadCodelist.action({ codelist: 'legal-form' })),
      dispatch(loadCodelist.action({ codelist: 'sport-org-competence-type' })),
      dispatch(
        loadCodelist.action({ codelist: 'sport-expert-competence-type' }),
      ),
      dispatch(loadCodelist.action({ codelist: 'nationality' })),
      dispatch(loadCodelist.action({ codelist: 'organization-profile-type' })),
    ]);
  }

  componentDidMount() {
    App.getInitialProps(this.props);
  }

  render() {
    const { currentPPO, logo, favicon } = this.props;

    const title = currentPPO.name || __('CRM Public');
    return (
      <Wrapper>
        <Helmet titleTemplate={`%s | ${title}`}>
          <title>{title}</title>
          {favicon && <link rel="shortcut icon" href={favicon} />}
          <meta
            property="og:url"
            content={`${getPPOUrl(currentPPO)}/contact`}
          />
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
          {logo && <meta property="og:image" content={logo} />}
          {/* <meta name="description" content="" /> */}
          {/* <meta name="twitter:description" content=""> */}
          <meta name="twitter:title" content={title} />
          {logo && <meta name="twitter:image" content={logo} />}
          <meta name="twitter:image:alt" content={title} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Header />
        <Content>{this.props.children}</Content>
        <Footer />
      </Wrapper>
    );
  }
}

export default compose(connect<IMapStateToProps>(mapStateToProps))(App);
