import {
  getTheme as getDefaultTheme,
  Theme,
} from '@sportnet/ui/lib/Themes/baseTheme';
import { css } from '@sportnet/ui/lib/Themes/styled-components';
import { Interpolation, ThemeProps } from 'styled-components';

const sizes: any = {
  xl: 1100,
  l: 992,
  m: 768,
  s: 480,
  xs: 320,
};

const media = Object.keys(sizes).reduce((acc: any, size: any) => {
  acc[size] = (
    ...args: [TemplateStringsArray, ...Array<Interpolation<ThemeProps<any>>>]
  ) => css`
    @media (min-width: ${sizes[size]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const customTheme = {
  backgroundColor: '#f7f7f7',
  buttonBackgroundColor: '#eee',
  sizes,
  media,
};

export interface ThemeInterface extends Theme {
  backgroundColor: string;
  buttonBackgroundColor: string;
  fontFamily: string;
}

type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> };

export const getTheme = (
  override?: RecursivePartial<Theme>,
): ThemeInterface => {
  return {
    ...getDefaultTheme(override || {}),
    ...customTheme,
  };
};

export const theme = getTheme();
export default theme;
